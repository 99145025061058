import { Plus } from "iconoir-react";
import GlobeIcon from "../asset/svg/GlobeIcon";
import LinkedinAvatar from "../asset/svg/LinkedinAvatar";
import LinkedinComment from "../asset/svg/LinkedinComment";
import { ReactComponent as LinkedinGroupReaction } from '../asset/svg/linkedinGroupReaction.svg';
import LinkedinRepost from "../asset/svg/LinkedinRepost";
import LinkedinSend from "../asset/svg/LinkedinSend";
import LinkedInThumbUp from "../asset/svg/LinkedinThumbUp";

const LinkedInPostCard = ({ content, name, profilePhoto, headLine }) => {

    return (
        <div className="bg-white px-3 max-w-[555px] w-full rounded-lg py-4 font-inter " style={{
            border: "1px solid #DEDDDA"
        }}>
            <div className="flex gap-2">
                <LinkedinAvatar width="48" height="48" src={profilePhoto} />
                <div className="">
                    <p className="text-sm font-semibold">{name}</p>
                    {
                        headLine ? (
                            <p className="text-xs font-normal" style={{
                                color: '#666'
                            }}>{headLine}</p>
                        ) : (
                            <div className="w-[60px] md:w-[217px] h-4" style={{ background: '#F4F1ED' }} />
                        )
                    }

                    <p className="text-xs flex gap-1 font-normal" style={{
                        color: '#666'
                    }}>
                        now •
                        <GlobeIcon />
                    </p>
                </div>
                <div className="gap-2 flex ml-auto px-2 py-[6px] items-center">
                    <Plus color="#3B65BE" />
                    <p className="text-sm font-bold" style={{ color: "#3B65BE" }}>Follow</p>
                </div>
            </div>
            <div className="my-2 text-sm paragraph-spacing"
                dangerouslySetInnerHTML={{ __html: content }}
            >
            </div>
            <div className="flex items-center justify-between">
                <div className="flex items-center gap-1 text-xs" style={{ color: '#666' }}>
                    <LinkedinGroupReaction />
                    <p>You and 500 others</p>
                </div>
                <div className="flex items-center text-xs gap-1" style={{ color: "#666" }}>
                    <p>250 comments</p>
                    <p>•</p>
                    <p>30 reposts</p>
                </div>
            </div>
            <div className="flex justify-between pt-1 mt-2 border-t " style={{ borderColor: '#E7E7E7' }}>
                <div className="flex flex-col md:flex-row items-center px-2 py-2.5 gap-2">
                    <LinkedInThumbUp />
                    <p className="text-[10px] md:text-sm font-bold" >Like</p>
                </div>
                <div className="flex flex-col md:flex-row items-center px-2 py-2.5 gap-2">
                    <LinkedinComment />
                    <p className="text-[10px] md:text-sm font-bold" >Comment</p>
                </div>
                <div className="flex flex-col md:flex-row items-center px-2 py-2.5 gap-2">
                    <LinkedinRepost />
                    <p className="text-[10px] md:text-sm font-bold" >Repost</p>
                </div>
                <div className="flex flex-col md:flex-row items-center px-2 py-2.5 gap-2">
                    <LinkedinSend />
                    <p className="text-[10px] md:text-sm font-bold" >Send</p>
                </div>
            </div>
        </div>
    )
}
export default LinkedInPostCard;