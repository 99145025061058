const PricingMobileOne = () => {
    return (
        <svg width="360" height="504" viewBox="0 0 360 504" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M411.685 5.66406L259.223 92.697C224.902 112.289 213.968 156.619 235.242 189.924V189.924C270.698 245.432 255.394 319.09 200.76 355.88L-11.3182 498.692" stroke="url(#paint0_linear_29_107)" strokeWidth="12" />
            <defs>
                <linearGradient id="paint0_linear_29_107" x1="777.643" y1="-6.32862" x2="418.554" y2="977.944" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#5F45FD" />
                    <stop offset="0.206167" stopColor="#FDA1F5" />
                    <stop offset="0.367482" stopColor="#FBDEBC" />
                    <stop offset="1" stopColor="#5F45FD" />
                </linearGradient>
            </defs>
        </svg>
    )
}
export default PricingMobileOne;