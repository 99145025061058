import { Sparks } from "iconoir-react";
import { links } from "../constent/links";
import Footer from "./Footer";
import Header from "./Header";
import CookiePopup from "./cookiePrefrence";

const MainLayout = ({ children,hideOverFlow }) => {
    return (
        <div className={`relative overflow-hidden ${hideOverFlow ? 'overflow-hidden': 'sm:overflow-visible'}`}>
            <Header />
            {children}
            <Footer />

            {/* popup compnent */}
            <CookiePopup/>

            <div className="md:hidden h-[90px]" />
        </div>
    )
}
export default MainLayout;