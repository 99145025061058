import ValueOneImage from '../../asset/image/valueOne.png';
import ValueTwoImage from '../../asset/image/valueTwo.png';
import HomeLineTwo from '../../asset/svg/HomeLineTwo';
const HomeValue = () => {
    return (
        <>
            <div className='relative'>
                <div className='hidden -z-10 md:block absolute -top-[80%]'>
                    <HomeLineTwo />
                </div>
                <div className="container mx-auto flex flex-col items-center justify-center pt-0 pb-[68px] md:pt-[94px] md:pb-[190px] gap-2">
                    <p className='text-bodyOne md:text-5xl font-light'>Value of</p>
                    <img src={ValueOneImage} alt="image1" className='w-[80%] md:w-[60%]' />
                    <p className='text-bodyOne md:text-5xl font-light'>equals</p>
                    <img src={ValueTwoImage} alt="image2" className='w-[90%] md:w-[70%]' />
                </div>
            </div>
        </>
    )
}
export default HomeValue;