import MobileHomeLine from "../../asset/svg/MobileHomeLine";
import PricingLineOne from "../../asset/svg/PricingLineOne";
import PricingMobileOne from "../../asset/svg/PricingMobileOne";
import PricingMobileTwo from "../../asset/svg/PricingMobileTwo";
import MainLayout from "../../layout/MainLayout";
import HomeFooter from "../home/HomeFooter";
import HomePlan from "../home/HomePlan";

const Pricing = () => {
    return (
        <MainLayout hideOverFlow={true}>
            <div >
                <div className="hidden h-full lg:block absolute top-0 w-full">
                    <PricingLineOne className={'w-full object-top'} />
                </div>
                <div className="absolute w-full top-[100px]  left-0 xs:hidden">
                    <PricingMobileOne className={'w-full h-full'} />
                </div>
                <div className="relative z-10">
                    <div className="mt-[33px] md:mt-[95px]">
                        <HomePlan />
                    </div>
                    <HomeFooter />
                </div>
                <div className="absolute w-full bottom-0  left-0 xs:hidden">
                    <PricingMobileTwo className={''} />
                </div>
            </div>
        </MainLayout>
    )
}

export default Pricing;