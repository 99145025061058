import MainLayout from "../../layout/MainLayout";

const Terms = () => {
    return (
        <MainLayout>
            <div className="container mx-auto mt-10">
                <h1 className="text-subHeadingOne">Terms of Use</h1>

                <div className="my-4">
                    <p className="text-bodyTwo">
                        This website available at https://superpen.io/ ("Website") is offered to you by Cozlo Technologies Private Limited ("Company"). The Website is the property of Company and its licensors only.
                        <div className="h-[10px]" />
                        You are advised to read these Terms of Use (“Terms”) carefully before accessing and using the Website or purchasing any service provided to You through the Website (the “Service”).
                        <div className="h-[10px]" />
                        Your access to and use of the Service is conditioned on your compliance with these Terms. These Terms shall apply to all visitors, users and others who access or use the Service.
                        <div className="h-[10px]" />
                        By accessing the Website, using the Website or placing any purchase orders for services sold on the Website you agree to be legally bound by these Terms and any additional terms and conditions set forth on this Website. If you disagree with any part of the Terms then You are requested to not continue to access the Service.

                    </p>
                </div>
                <div className="my-4">
                    <h2 className="text-bodyOneBold mb-1">PRIVACY POLICY </h2>
                    <p className="text-bodyTwo">
                        Any personal data provided, collected through or in connection with this Website shall only be used in accordance with the Privacy Policy available at https://superpen.io/privacy-policy. Additionally, by using the Website, you acknowledge and agree that internet transmissions are never completely private or secure, even upon the Company’s reasonable security measures and endeavours. You understand that any message or information you send to the Website may be read or intercepted by others, even if there is a special notice that a particular transmission is encrypted.
                    </p>
                </div>
                <div className="my-4">
                    <h2 className="text-bodyOneBold mb-1">BUYERS </h2>
                    <p className="text-bodyTwo">
                        By accessing or using the Service, you hereby represent that you are above the age of 18 years, to form a binding contract and are not barred from receiving the Service or any other services of a similar nature under the laws applicable in India.  You agree to deal with us in good faith and in compliance with all applicable laws.
                    </p>
                </div>
                <div className="my-4">
                    <h2 className="text-bodyOneBold mb-1">LINKS TO OTHER WEBSITES </h2>
                    <p className="text-bodyTwo">
                        Company has no control over and assumes no responsibility for the content, privacy policies, or practices of any third-party websites or services, that may be linked to the website via hyperlink, whether or not such hyperlink is provided by the Website or by a third party. You further acknowledge and agree that the Company shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or services available on or through any such third-party web sites or services.
                        <div className="h-[10px]" />

                        The inclusion of any hyperlink to such third-party web sites does not imply endorsement by the Company of the contents on those websites. We strongly advise you to read the terms and conditions and privacy policies of any third-party web sites or services that you visit.
                    </p>
                </div>

                <div className="my-4">
                    <h2 className="text-bodyOneBold mb-1">TERMINATION </h2>
                    <p className="text-bodyTwo">
                        We may terminate or suspend your access to the Website and/or our Service immediately, without prior notice or liability, for any reason whatsoever, including without limitation for any breach of the Terms.
                        <div className="h-[10px]" />
                        All provisions of the Terms which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability.
                    </p>
                </div>
                <div className="my-4">
                    <h2 className="text-bodyOneBold mb-1">INTELLECTUAL PROPERTY RIGHTS </h2>
                    <p className="text-bodyTwo">
                        The Website and the processes, and their selection and arrangement, including but not limited to all text, graphics, meta tags, data bases, content, user interfaces, visual interfaces, sounds and music (if any), artwork and computer code or source codes (collectively, the "Content") on the Website is owned and controlled by the Company; and the Content and its design, structure, selection, coordination, expression, look and feel and arrangement of such Content is protected by copyright, and other applicable intellectual property rights. You agree that you shall not copy, republish, post, display, translate, transmit, reverse engineer, reproduce or distribute any Content through any medium, whatsoever.
                        <div className="h-[10px]" />
                        The trademarks, logos and service marks relating to the brand “Super-Pen”, as displayed on the Website are the property of the Company. Any third-party trademarks, logos and service marks displayed on the Website (collectively, the “Marks”), shall belong to their vendors or respective third parties. You are not permitted to use the Marks without the prior consent of the Company, the relevant vendor, or the third party that may own the Marks.
                        <div className="h-[10px]" />
                        You agree and acknowledge, that any third-party material, logos, designs, trademarks, copyrights, content or other intellectual property used by You for the purposes of the Services (collectively, the “Third-Party IPR”), shall be used at your sole discretion and liability only. Company shall in no event be liable for any claims, actions, infringements or losses (whether direct or indirect) incurred by You, as a result of the use of the Third-Party IPR.
                        <div className="h-[10px]" />
                        Unless otherwise indicated or anything contained to the contrary or any proprietary material owned by a third party, the Company owns all intellectual property rights to and into the Services available on the Website, including, without limitation, any and all rights, title and interest in and to copyright, related rights, utility models, designs, know-how, trade secrets and inventions, goodwill, algorithms, source code, meta tags, databases, text, content, graphics, and icons.
                    </p>
                </div>
                <div className="my-4">
                    <h2 className="text-bodyOneBold mb-1">USE OF WEBSITE </h2>
                    <p className="text-bodyTwo">
                        You may not use any “deep-link”, “page-scrape”, “robot”, “spider” or other automatic device, program, algorithm or methodology, or any similar or equivalent manual process, to access, acquire, copy or monitor any portion of the Website or, or any Third Party IPR any Content, or in any way reproduce or circumvent the navigational structure or presentation of the Website or any Third Party IPR or any Content, to obtain or attempt to obtain any materials, documents or information through any means not purposely made available through the Website.  Company reserves the right to bar any such activity.
                        <div className="h-[10px]" />
                        You may not attempt to gain unauthorized access to any portion or feature of the Website, or any other systems or networks connected to the Website or to any Company server, or to any of the services offered on or through the Website, by hacking, password “mining” or any other illegitimate means.
                        <div className="h-[10px]" />
                        You may not probe, scan or test the vulnerability of the Website or any network connected to the Website, nor breach the security or authentication measures on the Website or any network connected to the Website. You may not reverse look-up, trace or seek to trace any information on any other user of or visitor to the Website, or any other customer of Company, including any Company account not owned by you, to its source, or exploit the Website or any service or information made available or offered by or through the Website, in any way where the purpose is to reveal any information, including but not limited to personal identification or information, other than your own information, as provided for by the Website.
                    </p>
                </div>

                <div className="my-4">
                    <h2 className="text-bodyOneBold mb-1">PAYMENT </h2>
                    <p className="text-bodyTwo">
                        You agree and acknowledge that the terms of the third-party payment gateway shall be applicable for the purposes of making, facilitating or generating any kind of payment for and through the Website.
                    </p>
                </div>
                <div className="my-4">
                    <h2 className="text-bodyOneBold mb-1">EXCUSING EVENTS </h2>
                    <p className="text-bodyTwo">
                        You acknowledge and agree that the Company is excused from performing any obligations and will not be responsible for any delays, lapses or failure where and so long as the Company is prevented from performing its obligations under these Terms by events or causes beyond its reasonable control. Company will endeavour to notify you of any delay and its expected duration. If the Company is prevented from performing its obligations for longer than a reasonable period of time, it may elect to cancel your order and issue you a refund of amount paid for the order without further liability to you.                    </p>
                </div>
                <div className="my-4">
                    <h2 className="text-bodyOneBold mb-1">WARRANTY </h2>
                    <p className="text-bodyTwo">
                        Company warrants that the Services being provided on the Website shall be free from material defects. Company hereby disclaims all other warranties, express or implied and whether statutory or otherwise, including any implied warranty of non-infringement, accuracy, merchantability or fitness for a particular purpose. Company disclaims any and all liability for the acts, omissions and conduct of any third parties in connection with or related to your use of the Website and/or any Services. Company shall under no circumstances be liable for any consequential damages.
                    </p>
                </div>
                <div className="my-4">
                    <h2 className="text-bodyOneBold mb-1">LIMITATION OF LIABILITY </h2>
                    <p className="text-bodyTwo">
                        The Company shall in no event be liable to any party for any direct, indirect, incidental, special, exemplary or consequential damages of any type whatsoever related to or arising from the provision of Services or the use of such Services by any person, including, without limitation, any lost profits or savings, damage to or loss of property or personal injury, even if the Company is expressly advised of the possibility of such damages. This exclusion and waiver of liability applies to all causes of action, whether based on contract, warranty, tort (including negligence) or any other legal theories.
                    </p>
                </div>
                <div className="my-4">
                    <h2 className="text-bodyOneBold mb-1">GOVERNING LAW AND DISPUTE RESOLUTION </h2>
                    <p className="text-bodyTwo">
                        These Terms shall be governed and construed in accordance with the laws of India, without regard to conflict of law provisions. Any claim under these Terms must be brought at the earliest, but not later than three (3) months after the cause of action arises, or such claim or cause of action is barred. Claims made under the separate terms and conditions of purchase for goods and services are not subject to this limitation.                                         </p>
                </div>
                <div className="my-4">
                    <h2 className="text-bodyOneBold mb-1">JURISDICTION  </h2>
                    <p className="text-bodyTwo">
                        Company administers and operates the Website from its location in Delhi, India. Although the Website is accessible worldwide, not all features or services discussed, referenced, provided or offered through or on the Website are available to all persons or in all geographic locations, or appropriate or available for use outside India.                                         </p>
                </div>
                <div className="my-4">
                    <h2 className="text-bodyOneBold mb-1">WAIVER  </h2>
                    <p className="text-bodyTwo">
                        Company’s failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. Neither the receipt of any funds by Company nor the reliance of any person on Company’s actions shall be deemed to constitute a waiver of any part of these Terms. Only a specific, written waiver signed by an authorized representative of the Company shall have any legal effect whatsoever.
                        <div className="h-[10px]" />
                        If any provision of these Terms is held to be legally invalid or unenforceable, then the invalid or unenforceable provision will be replaced by a valid enforceable provision that most closely matches the intent of the original provision and the remaining provisions of these Terms will remain in effect.
                    </p>
                </div>
                <div className="my-4">
                    <h2 className="text-bodyOneBold mb-1">ENTIRE AGREEMENT  </h2>
                    <p className="text-bodyTwo">
                        These Terms constitute the entire agreement between you and the Company regarding the Service, and supersede and replace any prior agreements you might have with the Company regarding the Service.
                    </p>
                </div>
                <div className="my-4">
                    <h2 className="text-bodyOneBold mb-1">MODIFICATION OF TERMS  </h2>
                    <p className="text-bodyTwo">
                        The Company reserves the right, at its sole discretion, to modify or replace these Terms at any time. Such amended Terms shall be effective upon posting on the Website. You are advised to regularly review any applicable terms and conditions.                         <div className="h-[10px]" />
                        By continuing to access or use our Service after any revisions become effective, you will be deemed to have accepted such revised terms. If you do not agree to the new terms, please stop using the Service.
                    </p>
                </div>
                <div className="my-4">
                    <h2 className="text-bodyOneBold mb-1">DISCLAIMER FOR USE OF WEBSITE  </h2>
                    <p className="text-bodyTwo">
                        All information (including, without limitation, content, text, images, graphics, links, and other materials) on the Website is provided "as is" and "as available". The Company does not warrant or make any representations that (i) the Website will meet your requirements, (ii) the Website will be uninterrupted, timely, secure, or error free, or (iii) the results that may be obtained from the use of the Website (including any Content, information and materials on this Website) will be correct, complete, accurate, reliable, or otherwise meet your requirements. Company cannot ensure that any files or other data you download from the Website will be free of viruses or contamination or destructive features.
                    </p>
                </div>
                <div className="my-4">
                    <h2 className="text-bodyOneBold mb-1">INDEMNIFICATION  </h2>
                    <p className="text-bodyTwo">
                        You agree and undertake that the Company shall not be liable for any consequences arising out of any improper and/ or negligent use of the Company’s Service.
                        <div className="h-[10px]" />
                        You agree to indemnify and hold the Company and its shareholders, directors, officers, managers, employees, representatives, agents, affiliates, successors, business partners and assigns (collectively, the “Indemnified Parties”) harmless from and against any and all damages, losses, costs, claims or expenses (including, without limitation, lawyers’ fees and expenses) incurred in connection with any suit, claim, demand or action brought against any of the Indemnified Parties arising from or relating to your use of the Third-Party IPR or the Service provided by the Company, or your breach of these Terms, including any terms and policies it incorporates by reference (individually and collectively a “Claim”). The Company has the right to control the defence, settlement, and resolution of any Claim, at your sole cost and expense.
                    </p>
                </div>

                <div className="my-4">
                    <h2 className="text-bodyOneBold mb-1">ADDITIONAL TERMS  </h2>
                    <p className="text-bodyTwo">
                        The following terms also govern and apply to your use of the Service or use of the Website, and they are incorporated herein by this reference:
                        <a className="block my-3 text-blue-600 underline" href="/privacy-policy">https://superpen.io/privacy-policy</a>
                        <a className="block my-3 text-blue-600 underline" href="/cookie-policy">
                            https://superpen.io/cookie-policy
                        </a>
                        Each of these policies may be changed from time to time and are effective immediately upon posting such changes on the Website.
                    </p>
                </div>

                <div className="my-4">
                    <h2 className="text-bodyOneBold mb-1">CONTACT US  </h2>
                    <p className="text-bodyTwo">
                        If you have any questions about these Terms, please contact us using the link at the bottom of this page.
                    </p>
                </div>

                <div className="my-10">
                    <p className="text-center text-bodyTwo italic mb-4">FOLLOWING SECTION IS FOR INTERNAL USE AT CTPL:</p>
                    <table className="w-full border border-black border-collapse text-bodyTwo">
                        <tr>
                            <td className="border border-black p-2">#</td>
                            <td className="border border-black p-2">Type of Information</td>
                            <td className="border border-black p-2">Document Data</td>
                        </tr>
                        <tr>
                            <td className="border border-black p-2">1.</td>
                            <td className="border border-black p-2">Document Title </td>
                            <td className="border border-black p-2">Cookie Policy </td>
                        </tr>
                        <tr>
                            <td className="border border-black p-2">2.</td>
                            <td className="border border-black p-2">Date of Release</td>
                            <td className="border border-black p-2">04/11/24</td>
                        </tr>
                        <tr>
                            <td className="border border-black p-2">3.</td>
                            <td className="border border-black p-2">Document Revision No. </td>
                            <td className="border border-black p-2">1</td>
                        </tr>
                        <tr>
                            <td className="border border-black p-2">4.</td>
                            <td className="border border-black p-2">Document Owner</td>
                            <td className="border border-black p-2">SuperPen</td>
                        </tr>
                    </table>

                </div>

            </div>
        </MainLayout>
    )
}
export default Terms;