
const UnChecked = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 30 30" fill="none">
            <g clipPath="url(#clip0_347_9847)">
                <path d="M24.375 4.6875H5.625C5.10723 4.6875 4.6875 5.10723 4.6875 5.625V24.375C4.6875 24.8928 5.10723 25.3125 5.625 25.3125H24.375C24.8928 25.3125 25.3125 24.8928 25.3125 24.375V5.625C25.3125 5.10723 24.8928 4.6875 24.375 4.6875Z" stroke="#C5C2CE" strokeWidth="1.875" strokeLinecap="round" strokeLinejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_347_9847">
                    <rect width="30" height="30" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

const Checked = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 30 30" fill="none">
            <g clipPath="url(#clip0_347_9862)">
                <path d="M24.375 3.75H5.625C5.12772 3.75 4.65081 3.94754 4.29917 4.29917C3.94754 4.65081 3.75 5.12772 3.75 5.625V24.375C3.75 24.8723 3.94754 25.3492 4.29917 25.7008C4.65081 26.0525 5.12772 26.25 5.625 26.25H24.375C24.8723 26.25 25.3492 26.0525 25.7008 25.7008C26.0525 25.3492 26.25 24.8723 26.25 24.375V5.625C26.25 5.12772 26.0525 4.65081 25.7008 4.29917C25.3492 3.94754 24.8723 3.75 24.375 3.75ZM20.3508 12.8508L13.7883 19.4133C13.7012 19.5004 13.5978 19.5696 13.484 19.6168C13.3702 19.664 13.2482 19.6882 13.125 19.6882C13.0018 19.6882 12.8798 19.664 12.766 19.6168C12.6522 19.5696 12.5488 19.5004 12.4617 19.4133L9.64922 16.6008C9.47331 16.4249 9.37448 16.1863 9.37448 15.9375C9.37448 15.6887 9.47331 15.4501 9.64922 15.2742C9.82513 15.0983 10.0637 14.9995 10.3125 14.9995C10.5613 14.9995 10.7999 15.0983 10.9758 15.2742L13.125 17.4246L19.0242 11.5242C19.1113 11.4371 19.2147 11.368 19.3285 11.3209C19.4423 11.2737 19.5643 11.2495 19.6875 11.2495C19.8107 11.2495 19.9327 11.2737 20.0465 11.3209C20.1603 11.368 20.2637 11.4371 20.3508 11.5242C20.4379 11.6113 20.507 11.7147 20.5541 11.8285C20.6013 11.9423 20.6255 12.0643 20.6255 12.1875C20.6255 12.3107 20.6013 12.4327 20.5541 12.5465C20.507 12.6603 20.4379 12.7637 20.3508 12.8508Z" fill="#7145FD" />
            </g>
            <defs>
                <clipPath id="clip0_347_9862">
                    <rect width="30" height="30" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}
const CheckBox = ({ checked, onChange }) => {
    return (
        <label className="cursor-pointer">
            <input type="checkbox" checked={checked} onChange={onChange} hidden/>
            {
                checked ? <Checked /> : <UnChecked />
            }
        </label>
    )
}
export default CheckBox;