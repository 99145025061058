import { Facebook, Instagram, X, Youtube } from "iconoir-react";
import LinkedinSimpleIcon from "../asset/svg/LinkedinSimpleIcon";
import LogoIcon from "../asset/svg/LogoIcon";
import WhatsappIcon from "../asset/svg/WhatsappIcon";
import { links, socialLinks } from "../constent/links";

const Footer = () => {
    const linkList = [
        {
            name: 'Login',
            path: links.login,
            external: true,
        },
        {
            name: 'Signup',
            path: links.sigup,
            external: true,
        },
        {
            name: 'Privacy Policy',
            path: links.privacyPolicy,
            external: false,
        },
        {
            name: 'Terms & Conditions',
            path: links.terms,
            external: false,
        },
        {
            name: 'About Us',
            path: links.aboutUs,
            external: true,
        },
        {
            name: 'Contact Us',
            path: links.contactUs,
            external: true,
        },
        {
            name: 'Pricing',
            path: links.pricing,
            external: true,
        },
        {
            name: 'Refund Policy',
            path: links.refundPolicy,
            external: true,
        },
    ]



    const socialLinkList = [
        {
            name: 'Facebook',
            icon: <Facebook />,
            path: socialLinks.facebook
        },
        {
            name: 'Insta',
            icon: <Instagram />,
            path: socialLinks.insta
        },
        {
            name: 'Linkedin',
            icon: <LinkedinSimpleIcon />,
            path: socialLinks.linkedin
        },
        // {
        //     name: 'X',
        //     icon: <X />,
        //     path: '#'
        // },
        {
            name: 'Youtube',
            icon: <Youtube />,
            path: socialLinks.youtube
        },
        {
            name: 'Whatsapp',
            icon: <WhatsappIcon />,
            path: socialLinks.whatsapp
        },
    ]
    return (
        <div className="py-3 md:py-8 container mx-auto relative z-10">
            <div className="flex md:gap-8 gap-3">
                <div className="rounded-[20px] bg-hover-gradient-1 px-4 flex items-center justify-center w-[45%] md:w-[20%] ">
                    <LogoIcon type="white" />
                </div>
                <div className="rounded-[20px] flex-1 flex-col lg:flex-row flex justify-between bg-star-light px-3 py-4 md:px-16 md:py-8">
                    <div className="text-white">
                        <p className="text-bodyOneBold">Company</p>
                        <div className="flex lg:items-center gap-2 mt-2 lg:gap-5 lg:mt-5 flex-col lg:flex-row">
                            {
                                linkList.slice(0,4).map((link) => (
                                    <a key={link.name} href={link.path} target={link.external ? "_blank" : "_self"} rel="noreferrer" className="text-bodyTwo cursor-pointer">{link.name}</a>
                                ))
                            }
                        </div>
                        <div className="flex lg:items-center gap-2 mt-2 lg:gap-5 lg:mt-5 flex-col lg:flex-row">
                            {
                                linkList.slice(4,).map((link) => (
                                    <a key={link.name} href={link.path} target={link.external ? "_blank" : "_self"} rel="noreferrer" className="text-bodyTwo cursor-pointer">{link.name}</a>
                                ))
                            }
                        </div>
                    </div>
                    <div className="text-white mt-4 lg:mt-0">
                        <p className="text-bodyTwoBold">Connect with us</p>
                        <div className="flex items-center flex-wrap gap-[6px] mt-3 lg:gap-2.5 lg:mt-5">
                            {
                                socialLinkList.map((item) => (
                                    <a key={item.name} href={item.path} target="_blank" rel="noreferrer" className="">
                                        {item.icon}
                                    </a>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
            <p className="text-bodyTwo text-gray-4 text-center mt-6">
                © 2024 Cozlo Technologies Pvt Ltd
                <br />
                All rights reserved
            </p>
        </div>
    )
}

export default Footer;