const ToogleON = () => {
    return (

        <svg width="56" height="32" viewBox="0 0 56 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="56" height="32" rx="16" fill="#7145FD" />
            <rect x="29" y="4" width="24" height="24" rx="12" fill="url(#paint0_linear_120_2712)" />
            <defs>
                <linearGradient id="paint0_linear_120_2712" x1="30.8667" y1="22.48" x2="54.1288" y2="18.0924" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#F7AFF1" />
                    <stop offset="0.509196" stop-color="#22F5EF" />
                    <stop offset="1" stop-color="#F7F6FF" />
                </linearGradient>
            </defs>
        </svg>

    )
}

export default ToogleON;