const HomeLineOne = ({ className }) => {
    return (
        <svg className={className} width="1315" height="3896" viewBox="0 0 1315 3896" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15 -61V397.19C15 469.493 46.3034 538.256 100.833 585.735L1019.67 1385.76C1074.2 1433.24 1105.5 1502.01 1105.5 1574.31V3604.44C1105.5 3690.64 1149.9 3770.75 1223 3816.44L1329.5 3883" stroke="url(#paint0_linear_13_686)" stroke-width="30" />
            <defs>
                <linearGradient id="paint0_linear_13_686" x1="-94" y1="140" x2="1784.64" y2="1886.25" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#5F45FD" />
                    <stop offset="0.395" stopColor="#FDA1F5" />
                    <stop offset="0.725" stopColor="#FBDEBC" />
                    <stop offset="1" stopColor="#5F45FD" />
                </linearGradient>
            </defs>
        </svg>
    )
}
export default HomeLineOne;