import { useEffect, useRef, useState } from "react";
import Slider from "react-slick";

const list = [
    {
        name: 'Uday Kumar',
        role: 'Growth & Marketing',
        company: 'LLA',
        profilePhoto: 'https://xsgames.co/randomusers/assets/avatars/male/20.jpg',
        review: <>
            Before SuperPen, our customer support team was overwhelmed with tickets and slow response times. Now, SuperPen handles 75% of inquiries, <p className="text-star-default text-bodyOneBold md:text-subHeadingTwo">reducing our average response time to 30 minutes and improving customer satisfaction to 95%.</p>  Plus, we've gained valuable customer insights for product improvements
        </>
    },
    {
        name: 'Uday Kumar',
        role: 'Growth & Marketing',
        company: 'LLA',
        profilePhoto: 'https://xsgames.co/randomusers/assets/avatars/male/21.jpg',
        review: <>
            Before SuperPen, our customer support team was overwhelmed with tickets and slow response times. Now, SuperPen handles 75% of inquiries, <p className="text-star-default text-bodyOneBold md:text-subHeadingTwo">reducing our average response time to 30 minutes and improving customer satisfaction to 95%.</p>  Plus, we've gained valuable customer insights for product improvements
        </>
    },
    {
        name: 'Uday Kumar',
        role: 'Growth & Marketing',
        company: 'LLA',
        profilePhoto: 'https://xsgames.co/randomusers/assets/avatars/male/22.jpg',
        review: <>
            Before SuperPen, our customer support team was overwhelmed with tickets and slow response times. Now, SuperPen handles 75% of inquiries, <p className="text-star-default text-bodyOneBold md:text-subHeadingTwo">reducing our average response time to 30 minutes and improving customer satisfaction to 95%.</p>  Plus, we've gained valuable customer insights for product improvements
        </>
    },
    {
        name: 'Uday Kumar',
        role: 'Growth & Marketing',
        company: 'LLA',
        profilePhoto: 'https://xsgames.co/randomusers/assets/avatars/male/23.jpg',
        review: <>
            Before SuperPen, our customer support team was overwhelmed with tickets and slow response times. Now, SuperPen handles 75% of inquiries, <p className="text-star-default text-bodyOneBold md:text-subHeadingTwo">reducing our average response time to 30 minutes and improving customer satisfaction to 95%.</p>  Plus, we've gained valuable customer insights for product improvements
        </>
    },
    {
        name: 'Uday Kumar',
        role: 'Growth & Marketing',
        company: 'LLA',
        profilePhoto: 'https://xsgames.co/randomusers/assets/avatars/male/24.jpg',
        review: <>
            Before SuperPen, our customer support team was overwhelmed with tickets and slow response times. Now, SuperPen handles 75% of inquiries, <p className="text-star-default text-bodyOneBold md:text-subHeadingTwo">reducing our average response time to 30 minutes and improving customer satisfaction to 95%.</p>  Plus, we've gained valuable customer insights for product improvements
        </>
    },
]
const HomeTestimonial = () => {
    const sliderRef = useRef(null);
    const [activeSlide, setActiveSlide] = useState(0);
    const settings = {
        centerMode: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        beforeChange: (current, next) => setActiveSlide(next),
        ref: sliderRef,
        responsive: [
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                }
            },
           
        ]
    };

    const handleGoTo = (index) => {
        sliderRef.current.slickGoTo(index)
    }

    useEffect(() => {
        if (sliderRef.current) {
            handleGoTo(2)
        }
    }, [])
    return (
        <div className="py-16">
            <p className="text-headingThree md:text-headingTwo text-space-dark text-center w-[80%] mx-auto md:w-full">Don’t just take our word for it</p>
            <p className="text-bodyOne md:text-subHeadingThree text-center text-gray-5 md:mt-2">Read what our clients have to say</p>
            <div className="overflow-hidden pb-10">
                <div className="mt-8 md:w-[150%] md:-ml-[370px]">
                    <Slider {...settings}>
                        {
                            list.map((item, index) => (
                                <div key={index} className="md:px-6">
                                    <div className="testimonail-card p-5 md:p-[38px] bg-[#FFF1FE] rounded-xl md:rounded-3xl">
                                        <p className="text-space-dark text-bodyOne md:text-subHeadingThree">
                                            {item.review}
                                        </p>
                                    </div>
                                </div>
                            ))
                        }
                    </Slider>
                </div>
                {/* Custom Dots Container */}
                <div className="mt-8 flex justify-center items-center">
                    <div
                        className="flex items-start transition-transform duration-500"
                        style={{
                            transform: `translateX(calc(50% - ${activeSlide * 75}px))`,
                            marginLeft: '-200px'
                        }}
                    >
                        {list.map((item, index) => {
                            const isActive = activeSlide === index;
                            return (
                                <div
                                    key={index}
                                    onClick={() => handleGoTo(index)}
                                    className="cursor-pointer mx-2 transition-all duration-300"
                                    style={{
                                        transform: `scale(${isActive ? 1.2 : 0.8})`,
                                    }}
                                >
                                    <div className="flex gap-2 flex-col items-center">
                                        <div
                                            className={`rounded-full overflow-hidden  transition-all duration-300 w-[66px] h-[66pxs] md:w-[75px] md:h-[75px]`}
                                            style={{
                                                border: "2px solid white",
                                                ...(isActive && {
                                                    border: "4px solid transparent",
                                                    backgroundImage:
                                                        "linear-gradient(#F7F6FF, #F7F6FF), linear-gradient(78deg, #22F5EF -1.83%, #7145FD 51.28%, #F7AFF1 106.56%)",
                                                    backgroundOrigin: "border-box",
                                                    backgroundClip: "padding-box, border-box",
                                                }),
                                            }}
                                        >
                                            <img className="w-full h-full" src={item.profilePhoto} alt={item.name} />
                                        </div>
                                        {
                                            isActive && (
                                                <div className="text-center">
                                                    <p className="text-bodyOneBold text-space-dark">{item.name}</p>
                                                    <p className="text-bodyOne md:text-bodyTwo text-gray-5">{item.role}
                                                        <br />
                                                        {item.company}
                                                    </p>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}
export default HomeTestimonial;