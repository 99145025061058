const LogoIcon = ({ type = 'default', width = '170', height = '40' }) => {
    let firstColor = '#120B2A';
    let secondColor = '#7145FD';
    let thirdColor = '#5F38DB';

    if (type === 'white') {
        firstColor = 'white';
        secondColor = 'white';
        thirdColor = 'white';
    }
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 170 40" fill="none">
            <g clipPath="url(#clip0_296_5290)">
                <path d="M56.4031 27.7132C55.2153 27.7249 54.0378 27.4879 52.9438 27.0168C51.9087 26.571 50.9768 25.9097 50.2079 25.0753C49.4451 24.2458 48.8743 23.2532 48.5373 22.17L51.5102 20.8574C51.9322 21.9329 52.6432 22.8656 53.5619 23.5488C54.4417 24.1957 55.5021 24.5384 56.5873 24.5266C57.1504 24.5397 57.7101 24.4349 58.2317 24.2185C58.6597 24.0419 59.0325 23.7501 59.3101 23.3743C59.5699 22.9987 59.7037 22.5477 59.6915 22.0884C59.7104 21.5531 59.5268 21.0309 59.1784 20.6294C58.7534 20.1958 58.2222 19.8859 57.6398 19.7318L53.8777 18.5008C52.5432 18.1412 51.3437 17.3861 50.4314 16.3313C49.6616 15.3734 49.2514 14.1682 49.2739 12.9308C49.2539 11.8613 49.5419 10.8093 50.1025 9.90454C50.6712 9.01512 51.4731 8.30553 52.4175 7.85607C53.4828 7.34826 54.6483 7.09604 55.8244 7.11885C56.9336 7.10638 58.0339 7.32071 59.0602 7.74914C60.0131 8.1489 60.8767 8.74091 61.5988 9.48948C62.3097 10.2316 62.8528 11.1228 63.1903 12.1007L60.244 13.4387C59.9082 12.506 59.3029 11.6987 58.5077 11.1229C57.7186 10.5728 56.7806 10.2871 55.8244 10.3055C55.2829 10.2952 54.7453 10.4002 54.246 10.6136C53.8206 10.7947 53.452 11.0911 53.1804 11.4704C52.9087 11.8846 52.776 12.3771 52.8021 12.8744C52.8282 13.3717 53.0117 13.847 53.3252 14.2294C53.7598 14.7016 54.3157 15.0403 54.9298 15.2072L58.534 16.358C59.8728 16.7374 61.0861 17.4777 62.0459 18.5008C62.8356 19.4375 63.2531 20.6407 63.2166 21.8745C63.2361 22.9497 62.9333 24.0053 62.3486 24.9008C61.7478 25.7953 60.9207 26.5079 59.9548 26.9634C58.842 27.4838 57.6275 27.7402 56.4031 27.7132Z" fill={firstColor} />
                <path d="M71.2403 27.7132C70.2306 27.7383 69.2337 27.4788 68.3597 26.9634C67.5487 26.4691 66.899 25.7424 66.4918 24.8741C66.0434 23.894 65.8229 22.8218 65.8474 21.7409V12.8239H69.2936V21.4469C69.2812 21.9994 69.4032 22.5466 69.6488 23.0395C69.8812 23.4907 70.2377 23.8633 70.6747 24.1116C71.1366 24.3743 71.6584 24.5083 72.1874 24.4999C72.7123 24.5093 73.2301 24.3752 73.6867 24.1116C74.1222 23.8556 74.478 23.4796 74.7129 23.0268C74.9695 22.5133 75.0961 21.9426 75.0812 21.3667V12.8239H78.5275V27.3911H75.2654V24.5266L75.5546 25.0345C75.2589 25.882 74.676 26.5944 73.9106 27.0436C73.0944 27.5035 72.173 27.7346 71.2403 27.7132Z" fill={firstColor} />
                <path d="M81.7367 32.7472V12.8239H84.9725V15.7151L84.6567 14.9919C85.1474 14.2074 85.8386 13.5737 86.6563 13.1587C87.5642 12.7056 88.5654 12.4804 89.5763 12.5017C90.8651 12.4843 92.1325 12.8373 93.233 13.5203C94.312 14.1899 95.2041 15.1302 95.8241 16.2511C96.4729 17.4278 96.8042 18.7583 96.7843 20.1075C96.8001 21.4545 96.4739 22.7829 95.8372 23.9638C95.2328 25.0957 94.3429 26.0432 93.2593 26.7087C92.1384 27.3876 90.8541 27.7354 89.55 27.7132C88.5642 27.7237 87.5887 27.5083 86.6957 27.0829C85.8555 26.6882 85.1403 26.0625 84.6305 25.2765L85.183 24.5801V32.7472H81.7367ZM89.1555 24.4999C89.8945 24.5129 90.6227 24.3182 91.26 23.9371C91.8704 23.565 92.3668 23.0272 92.6936 22.3839C93.0459 21.6795 93.2221 20.8977 93.2067 20.1075C93.2231 19.3217 93.0468 18.5441 92.6936 17.8452C92.3563 17.1907 91.8467 16.6448 91.2217 16.2686C90.5968 15.8924 89.8813 15.7007 89.1555 15.7151C88.4404 15.7028 87.7364 15.8962 87.1244 16.273C86.5125 16.6498 86.0174 17.1947 85.6961 17.8452C85.3584 18.5523 85.183 19.3283 85.183 20.1145C85.183 20.9008 85.3584 21.6767 85.6961 22.3839C86.0212 23.0301 86.5176 23.5708 87.1289 23.9447C87.7403 24.3187 88.4423 24.511 89.1555 24.4999Z" fill={firstColor} />
                <path d="M106.203 27.7133C104.846 27.7446 103.507 27.392 102.335 26.6946C101.245 26.0322 100.353 25.0785 99.7572 23.9371C99.1369 22.7508 98.8202 21.4245 98.8364 20.0808C98.8111 18.7213 99.1329 17.3785 99.7703 16.1836C100.372 15.0727 101.252 14.1444 102.322 13.4936C103.414 12.8285 104.667 12.4851 105.939 12.5017C106.953 12.481 107.961 12.6726 108.899 13.0644C109.722 13.4199 110.462 13.9493 111.069 14.6177C111.663 15.2756 112.128 16.0437 112.437 16.88C112.758 17.758 112.918 18.6882 112.911 19.6249C112.911 19.8753 112.898 20.1342 112.871 20.4015C112.852 20.6389 112.803 20.8728 112.727 21.0979H101.678V18.4206H110.78L109.149 19.6784C109.312 18.9499 109.271 18.1894 109.03 17.4836C108.811 16.8638 108.406 16.3297 107.873 15.9571C107.301 15.5706 106.625 15.374 105.939 15.3943C105.26 15.3827 104.593 15.5735 104.019 15.943C103.44 16.3307 102.994 16.8923 102.743 17.5497C102.439 18.3644 102.326 19.2404 102.414 20.1075C102.331 20.9152 102.453 21.7312 102.769 22.4767C103.054 23.1386 103.531 23.6953 104.137 24.0708C104.769 24.4529 105.494 24.6478 106.229 24.6335C106.933 24.6554 107.629 24.4792 108.241 24.1242C108.786 23.7937 109.235 23.3224 109.543 22.7581L112.332 24.151C112.041 24.8625 111.59 25.4951 111.016 25.9982C110.394 26.5495 109.676 26.9766 108.899 27.2574C108.033 27.5699 107.121 27.7242 106.203 27.7133Z" fill={firstColor} />
                <path d="M115.515 27.3911V12.8239H118.751V16.064L118.488 15.5814C118.71 14.6493 119.269 13.8366 120.053 13.305C120.835 12.8582 121.721 12.636 122.618 12.6621H123.46V15.7685H122.223C121.787 15.7486 121.352 15.8205 120.945 15.9795C120.537 16.1385 120.167 16.3814 119.856 16.6929C119.545 17.041 119.305 17.4491 119.151 17.893C118.998 18.3369 118.933 18.8076 118.961 19.2774V27.3911H115.515Z" fill={firstColor} />
                <path d="M126.064 27.3911V7.44104H132.799C133.965 7.41502 135.12 7.67665 136.166 8.20359C137.101 8.68513 137.881 9.42854 138.415 10.3463C138.964 11.3191 139.241 12.4257 139.218 13.547C139.242 14.6641 138.965 15.7665 138.415 16.7337C137.881 17.6429 137.106 18.3806 136.179 18.8624C135.13 19.3929 133.97 19.6546 132.799 19.6249H128.169V27.3911H126.064ZM128.169 17.6172H132.904C133.669 17.6351 134.427 17.4652 135.114 17.122C135.73 16.8055 136.243 16.312 136.587 15.7024C136.95 15.0357 137.132 14.2824 137.113 13.5203C137.133 12.7621 136.951 12.0125 136.587 11.3509C136.24 10.7473 135.728 10.2591 135.114 9.94535C134.427 9.60163 133.669 9.43129 132.904 9.44871H128.169V17.6172Z" fill={firstColor} />
                <path d="M148.583 27.7132C147.329 27.7299 146.094 27.3913 145.018 26.7354C143.958 26.0849 143.09 25.155 142.506 24.044C141.88 22.8504 141.563 21.5139 141.585 20.1609C141.56 18.8238 141.867 17.5019 142.48 16.3187C143.046 15.2349 143.886 14.3244 144.913 13.6807C145.954 13.0337 147.153 12.6996 148.373 12.717C149.311 12.6998 150.241 12.8964 151.096 13.2924C151.873 13.6614 152.567 14.1902 153.134 14.8456C154.252 16.147 154.868 17.8163 154.87 19.5447C154.87 19.7234 154.861 19.9246 154.844 20.1483C154.826 20.3706 154.8 20.5985 154.765 20.8306H142.848V18.9566H153.634L152.687 19.76C152.856 18.8674 152.741 17.9433 152.358 17.122C151.998 16.365 151.438 15.7249 150.74 15.2734C150.033 14.817 149.21 14.58 148.373 14.591C147.513 14.5859 146.67 14.8272 145.939 15.2874C145.19 15.756 144.584 16.4285 144.19 17.229C143.753 18.1477 143.58 19.1732 143.69 20.1877C143.58 21.2151 143.768 22.2526 144.229 23.1732C144.649 23.9926 145.287 24.6748 146.071 25.1414C146.831 25.6008 147.699 25.8415 148.583 25.8379C149.507 25.871 150.418 25.6088 151.187 25.088C151.883 24.5954 152.466 23.9562 152.897 23.214L154.581 24.1243C154.276 24.8016 153.837 25.4078 153.292 25.9054C152.687 26.4666 151.988 26.9114 151.227 27.2181C150.385 27.556 149.487 27.7242 148.583 27.7132Z" fill={firstColor} />
                <path d="M158.343 27.3911V13.0377H160.316V15.8234L159.869 15.7151C160.198 14.8148 160.802 14.0456 161.592 13.5203C162.417 12.9762 163.384 12.6964 164.367 12.717C165.311 12.7026 166.241 12.9424 167.064 13.412C167.851 13.8671 168.505 14.5282 168.958 15.3268C169.428 16.1615 169.669 17.1096 169.655 18.0717V27.3911H167.682V18.8483C167.704 18.081 167.547 17.3193 167.221 16.6268C166.934 16.0409 166.487 15.5522 165.932 15.2198C165.352 14.8829 164.693 14.7117 164.025 14.7246C163.353 14.7128 162.69 14.8837 162.105 15.2198C161.54 15.5531 161.083 16.0463 160.79 16.6394C160.458 17.3257 160.295 18.0838 160.316 18.8483V27.3911H158.343Z" fill={firstColor} />
                <path d="M7.24483 0C7.01227 -1.94651e-06 6.78329 0.0583344 6.57815 0.169849C6.37301 0.281365 6.19802 0.442622 6.06867 0.639359L0.239117 9.50567C0.0837057 9.74205 0.000506012 10.02 2.30079e-06 10.3044C-0.000501411 10.5889 0.0817134 10.8671 0.236287 11.104L18.9821 39.8374C19.0278 39.9076 19.0962 39.9592 19.1754 39.9833C19.2546 40.0074 19.3396 40.0026 19.4157 39.9696C19.4918 39.9365 19.554 39.8774 19.5918 39.8025C19.6295 39.7276 19.6402 39.6417 19.6221 39.5595L14.4033 15.8096C14.3613 15.6183 14.3581 15.4203 14.3938 15.2276C14.4295 15.035 14.5035 14.8519 14.6112 14.6894L21.8833 3.71612C21.9477 3.61882 22.0346 3.53903 22.1363 3.48369C22.2379 3.42836 22.3514 3.39915 22.4667 3.39861C22.582 3.39808 22.6956 3.42623 22.7978 3.48061C22.9 3.535 22.9876 3.61398 23.0529 3.71068L30.4036 14.5867C30.5142 14.7504 30.5904 14.9356 30.6274 15.1307C30.6643 15.3258 30.6611 15.5266 30.6182 15.7205L27.826 28.3124C27.8078 28.3944 27.8185 28.4804 27.856 28.5554C27.8936 28.6303 27.9558 28.6895 28.0317 28.7227C28.1077 28.7558 28.1927 28.7609 28.2719 28.7369C28.3512 28.7129 28.4197 28.6615 28.4655 28.5915L39.9178 11.1046C40.0731 10.8676 40.1558 10.589 40.1555 10.3042C40.1553 10.0193 40.0721 9.7409 39.9164 9.50416L34.0879 0.639352C33.9585 0.442623 33.7836 0.281372 33.5784 0.169859C33.3733 0.0583461 33.1443 6.74199e-06 32.9117 0L7.24483 0Z" fill={secondColor} />
                <path d="M22.0023 14.0718L22.3574 12.7292L22.7125 14.0718C22.8307 14.5189 23.0623 14.9265 23.3839 15.2539C23.7055 15.5813 24.106 15.817 24.5452 15.9374L25.8641 16.2989L24.5452 16.6603C24.106 16.7807 23.7055 17.0164 23.3839 17.3438C23.0623 17.6712 22.8307 18.0788 22.7125 18.5259L22.3574 19.8685L22.0023 18.5259C21.884 18.0788 21.6525 17.6712 21.3309 17.3438C21.0092 17.0164 20.6088 16.7807 20.1696 16.6603L18.8507 16.2989L20.1696 15.9374C20.6088 15.817 21.0092 15.5813 21.3309 15.2539C21.6525 14.9265 21.884 14.5189 22.0023 14.0718Z" fill={thirdColor} />
            </g>
            <defs>
                <clipPath id="clip0_296_5290">
                    <rect width="169.655" height={height} fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}
export default LogoIcon;