
const PricingLineOne = ({ className }) => {
    return (
        <svg className={className} xmlns="http://www.w3.org/2000/svg" width="1315" height="2573" viewBox="0 0 1315 2573" fill="none">
            <path d="M15 -61V397.19C15 469.493 46.3034 538.256 100.833 585.735L1019.67 1385.76C1074.2 1433.24 1105.5 1502.01 1105.5 1574.31V2125.3C1105.5 2205.23 1143.71 2280.33 1208.31 2327.39L1989 2896" stroke="url(#paint0_linear_38_10098)" strokeWidth="30" />
            <defs>
                <linearGradient id="paint0_linear_38_10098" x1="-94.0001" y1="140" x2="1784.64" y2="1886.25" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#5F45FD" />
                    <stop offset="0.395" stopColor="#FDA1F5" />
                    <stop offset="0.725" stopColor="#FBDEBC" />
                    <stop offset="1" stopColor="#5F45FD" />
                </linearGradient>
            </defs>
        </svg>
    )
}
export default PricingLineOne;