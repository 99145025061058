const LinkedinIcon = ({ width = '54', height = '53',className }) => {
    return (
        <svg className={className} width={width} height={height} viewBox="0 0 54 53" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="g1905">
                <path id="path1901" fillRule="evenodd" clipRule="evenodd" d="M49.3357 -1.52588e-05L4.16963 -1.52588e-05C2.00925 -1.52588e-05 0.174438 1.7086 0.174438 3.81346L0.174438 49.184C0.174438 51.2914 1.3783 53 3.53868 53H48.7048C50.8677 53 53.1744 51.2914 53.1744 49.184V3.81346C53.1744 1.7086 51.4986 -1.52588e-05 49.3357 -1.52588e-05Z" fill="white" />
                <path id="path1903" fillRule="evenodd" clipRule="evenodd" d="M49.3357 -1.52588e-05L4.16963 -1.52588e-05C2.00925 -1.52588e-05 0.174438 1.7086 0.174438 3.81346L0.174438 49.184C0.174438 51.2914 1.3783 53 3.53868 53H48.7048C50.8677 53 53.1744 51.2914 53.1744 49.184V3.81346C53.1744 1.7086 51.4986 -1.52588e-05 49.3357 -1.52588e-05ZM20.3649 20.1905H27.4997V23.8273H27.578C28.6657 21.8663 31.8785 19.875 35.851 19.875C43.4754 19.875 45.603 23.9232 45.603 31.4214V45.4285H38.0316V32.8019C38.0316 29.4453 36.6914 26.5 33.5569 26.5C29.7509 26.5 27.9363 29.0768 27.9363 33.3067V45.4285H20.3649V20.1905ZM7.74587 45.4285H15.3173V20.1905H7.74587V45.4285ZM16.2637 11.3571C16.264 11.9785 16.1419 12.5938 15.9043 13.1679C15.6668 13.7421 15.3185 14.2638 14.8793 14.7033C14.4401 15.1428 13.9186 15.4915 13.3446 15.7295C12.7707 15.9674 12.1554 16.09 11.5341 16.0902C10.9128 16.0903 10.2975 15.9681 9.72337 15.7304C9.14927 15.4928 8.62761 15.1444 8.18818 14.7051C7.74874 14.2658 7.40015 13.7443 7.1623 13.1703C6.92445 12.5962 6.80201 11.981 6.80196 11.3596C6.80186 10.1051 7.30007 8.90187 8.18702 8.01462C9.07397 7.12736 10.277 6.62873 11.5316 6.6284C12.7861 6.62806 13.9894 7.12605 14.8769 8.01283C15.7643 8.89962 16.2631 10.1026 16.2637 11.3571Z" fill="#0A66C2" />
            </g>
        </svg>

    )
}

export default LinkedinIcon;