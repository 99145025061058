import { useEffect } from "react";
import LogoIcon from "../asset/svg/LogoIcon";
import RestrictPopupIcon from '../asset/svg/RestrictPopupIcon.svg';
import { Xmark } from "iconoir-react";
import { links } from "../constent/links";
import useResponsive from "../hook/useResponsive";

const BGSVG = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 898 553" fill="none">
            <path d="M513.5 -208L513.5 225.29C513.5 347.634 636.426 431.774 750.475 387.494L994.7 292.674C1110.98 247.529 1118.77 85.9583 1007.37 29.8355L802.243 -73.5103C800.084 -74.5979 797.767 -75.3384 795.378 -75.7045L218.338 -164.121C202.735 -166.512 189.417 -152.835 192.22 -137.302L224.115 39.4442C252.942 199.188 196.935 362.461 76.123 470.874L-4.47054 543.196" stroke="url(#paint0_linear_839_10368)" strokeWidth="25" />
            <defs>
                <linearGradient id="paint0_linear_839_10368" x1="670.145" y1="-215.331" x2="-246.5" y2="811.5" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#5F45FD" />
                    <stop offset="0.33" stopColor="#FDA1F5" />
                    <stop offset="0.635" stopColor="#FBDEBC" />
                    <stop offset="1" stopColor="#3091E2" />
                </linearGradient>
            </defs>
        </svg>
    )
}

const MobileBGSVG = () => {
    return (
        <svg width="100%" height="100%" viewBox="0 0 340 230" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M218.5 -54L166.783 121.428C150.09 178.049 198.728 232.481 256.863 222.24L498.517 179.67C546.967 171.135 569.269 114.4 539.599 75.1576L420.447 -82.4409C418.297 -85.2837 414.854 -86.844 411.3 -86.5858L-32.333 -54.3583C-34.8554 -54.1751 -36.5506 -51.6918 -35.8024 -49.276L11.5896 103.751C26.1937 150.907 -33.911 184.58 -66.5 147.5V147.5" stroke="url(#paint0_linear_837_9792)" stroke-width="12" />
            <defs>
                <linearGradient id="paint0_linear_837_9792" x1="271.077" y1="-67.213" x2="-147.888" y2="402.114" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#5F45FD" />
                    <stop offset="0.33" stop-color="#FDA1F5" />
                    <stop offset="0.635" stop-color="#FBDEBC" />
                    <stop offset="1" stop-color="#3091E2" />
                </linearGradient>
            </defs>
        </svg>

    )
}
const RestrictPopup = ({ title, subtitle, btnText, onClose }) => {
    const { isTablet, isMobile } = useResponsive();
    console.log(title)
    useEffect(() => {
        document.body.style.overflow = "hidden";
        return () => {
            document.body.style.overflow = "auto";
        };
    }, []);
    return (
        <div className="fixed top-0 left-0 h-full w-full z-[99] flex items-center justify-center">
            <div className="absolute top-0 left-0 h-full w-full blur-[20px]" style={{
                background: 'rgba(106, 101, 167, 0.52)',
            }} />
            <div className="relative overflow-hidden z-10 bg-gray-moon rounded-[25px] p-6 md:p-8 w-[95%] md:w-[70%]">
                <div className="absolute -top-[30%] md:-top-[10%] -left-2 w-full h-full">
                    {
                        isTablet ? <MobileBGSVG /> : <BGSVG />
                    }
                </div>
                <div className="relative z-10">
                    <div className="flex justify-between items-center">
                        <LogoIcon width={isMobile ? '101' : "127"} />
                        {
                            onClose && (
                                <div className="outlined-btn px-[15px] border-none cursor-pointer" onClick={onClose}>
                                    <Xmark />
                                </div>
                            )
                        }
                    </div>
                    <div className="flex justify-center">
                        <img src={RestrictPopupIcon} className="md:w-[70%] h-full" alt="congo" />
                    </div>
                    <p className="mt-7 md:mt-9 text-subHeadingTwoBold md:text-subHeadingThreeBold text-center bg-clip-text text-transparent bg-base-gradient">
                        {
                            title || (
                                <>Woohooo! <br />
                                    Congrats on your viral LinkedIn Post</>
                            )
                        }

                    </p>
                    <p className="text-bodyTwo text-center text-gray-6">
                        {
                            subtitle || (
                                <>Get Started with SuperPen now & rule on Linkedin
                                </>
                            )
                        }
                    </p>

                    <div className="mt-7 md:mt-9 flex justify-center">
                        <a href={links.sigup} target="_blank" className="filled-btn small md:regular">{btnText || "Get Started now"}</a>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default RestrictPopup;