const HomeLineTwo = () => {
    return (
        <svg width="165" height="1026" viewBox="0 0 165 1026" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M-841.439 359.69L-236.405 773.661C-178.783 813.087 -103.175 814.252 -44.3658 776.621L82.678 695.327C168.636 640.323 171.685 515.833 88.5242 456.686L2.98459 395.848L-526.285 19.418C-541.952 8.27524 -563.564 20.1302 -562.586 39.3307L-536.862 544.457C-529.679 685.504 -586.574 822.252 -691.687 916.577L-800.47 1014.2" stroke="url(#paint0_linear_296_5285)" stroke-width="30" />
            <defs>
                <linearGradient id="paint0_linear_296_5285" x1="-125.855" y1="255.669" x2="-776.765" y2="1034.02" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#5F45FD" />
                    <stop offset="0.33" stopColor="#FDA1F5" />
                    <stop offset="0.635" stopColor="#FBDEBC" />
                    <stop offset="1" stopColor="#3091E2" />
                </linearGradient>
            </defs>
        </svg>

    )
}
export default HomeLineTwo;