export const getPlans = (tenure) => ([
    {
        name: 'Super Star',
        description: 'For those who want to write independently',
        amount: tenure === 'month' ? '₹1,999' : '₹1,399',
        amountBeforeDiscount: tenure === 'month' ? '₹3,999' : '₹2,799',
        billingPeriod: tenure === 'month' ? '/month' : '/month for 12 months',
        isFreeTrail: true,
        color: '#2789EC',
        background: '#F0FAFF',
        features: [
            {
                title: "Inspiration Hub",
                description: "Browse over 8M viral posts to spark ideas and stay relevant."
            },
            {
                title: "Super Recommendations ",
                description: "Get personalized recommendations based on your impressions, engagement, and profile visits."
            },
        ],
    },
    {
        name: 'Super Nova',
        description: 'For Linkedin Creators, Founders & Freelancers',
        amount: tenure === 'month' ? '₹2,999' : '₹2,099',
        amountBeforeDiscount: tenure === 'month' ? '₹5,999' : '₹4,199',
        billingPeriod: tenure === 'month' ? '/month' : '/month for 12 months',
        best: true,
        isFreeTrail: true,
        color: '#7145FD',
        background: '#F7F6FF',
        features: [
            {
                title: "Inspiration Hub",
                description: "Browse over 8M viral posts to spark ideas and stay relevant."
            },
            {
                title: "Super Recommendations ",
                description: "Get personalized recommendations based on your impressions, engagement, and profile visits."
            },
            {
                title: "Personalized AI Writer",
                description: "Create and edit posts in seconds—no more staring at a blank screen."
            },
        ],
    },
    {
        name: 'Enterprise',
        description: 'For Agencies & Businesses',
        amount: 'Custom Prices',
        billingPeriod: '/month',
        color: '#FF90F6',
        background: '#FFF4FE',
        features: [
            {
                title: "Inspiration Hub",
                description: "Browse over 8M viral posts to spark ideas and stay relevant."
            },
            {
                title: "Super Recommendations ",
                description: "Get personalized recommendations based on your impressions, engagement, and profile visits."
            },
            {
                title: "Personalized AI Writer",
                description: "Create and edit posts in seconds—no more staring at a blank screen."
            },
        ],
    },
]);