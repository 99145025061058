import HomeLineOne from "../../asset/svg/HomeLineOne";
import HomeLineThree from "../../asset/svg/HomeLineThree";
import MobileHomeLine from "../../asset/svg/MobileHomeLine";
import Footer from "../../layout/Footer";
import Header from "../../layout/Header";
import MainLayout from "../../layout/MainLayout";
import HomeFooter from "./HomeFooter";
import HomeForm from "./HomeForm";
import HomeHero from "./HomeHero";
import HomeJourney from "./HomeJourney";
import HomePlan from "./HomePlan";
import HomeTestimonial from "./HomeTestimonial";
import HomeTutorial from "./HomeTutorial";
import HomeValue from "./HomeValue";

const Home = () => {
    return (
        <MainLayout>
            <div className="hidden lg:block absolute top-0 w-full">
                <HomeLineOne className={'w-full object-top'} />
            </div>
            <div className="absolute w-full top-[50px]  left-0 xs:hidden">
                <MobileHomeLine className={'w-full h-full'} />
            </div>
            <div className="relative z-10">
                <HomeHero />
                <HomeTutorial />
                {/* <HomeTestimonial /> */}
                <HomeValue />
                <HomePlan/>
                <HomeJourney />
                {/* <HomeForm /> */}
                <HomeFooter />
            </div>
            <div className="hidden absolute overflow-hidden bottom-0 md:flex justify-end w-full">
                <HomeLineThree className={''} />
            </div>
        </MainLayout>
    )
}
export default Home;