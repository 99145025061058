import LinkedinIcon from "../../asset/svg/LinkedinIcon";
import FooterHeroImage from '../../asset/image/footer-hero.png';
import { links } from "../../constent/links";

const HomeFooter = () => {

    return (
        <div className="container mx-auto py-5 md:py-8">
            <div className="p-4 md:p-8  rounded-[20px] flex flex-col md:flex-row justify-between gap-4 md:gap-0">
                <div className="py-3 md:px-4 md:py-8 flex items-center md:items-start flex-col gap-[30px] md:gap-[44px]">
                    <div className="text-headingThree md:text-headingOne">
                        <p>Ready to rule</p>
                        <p className="flex gap-1 md:gap-4">on <span className="flex gap-1 md:gap-4 items-center text-space-dark ">Linked <LinkedinIcon className='w-[30px] h-[30px] md:w-[40px] md:h-[40px]' /> </span> ?</p>
                    </div>
                    <a href={links.sigup} target="_blank" className="filled-btn small md:regular ">Get started now</a>
                </div>
                <div className="md:w-max">
                    <img src={FooterHeroImage} alt="footer-hero" className="w-full h-full object-contain"/>
                </div>
            </div>
        </div>
    )
}
export default HomeFooter;