export const links = {
    sigup: 'http://app.superpen.io/register?utm_source=website',
    login: 'http://app.superpen.io/login?utm_source=website',
    privacyPolicy: '/privacy-policy',
    cookiePolicy: '/cookie-policy',
    terms: '/termsofuse',
    tryForFree:'/product-demo',
    aboutUs:'https://sincere-face-27c.notion.site/About-Us-1387fbc1d7fb80d8b17bebc3a2ed42e0',
    contactUs:'https://api.whatsapp.com/send/?phone=919910302730&text&type=phone_number&app_absent=0',
    pricing:'https://sincere-face-27c.notion.site/Pricing-1387fbc1d7fb80a1bfabfe2aa996eafd?pvs=4',
    refundPolicy:'https://sincere-face-27c.notion.site/Refund-policy-1387fbc1d7fb80df8590e5bf638e31f7?pvs=4'
}

export const socialLinks={
    insta:'https://www.instagram.com/superpen.io/',
    linkedin:'https://www.linkedin.com/company/superpen/',
    facebook:'https://www.facebook.com/superpen.io',
    whatsapp:'https://api.whatsapp.com/send/?phone=919910302730&text&type=phone_number&app_absent=0',
    youtube:'https://www.youtube.com/@SuperpenViralContent',
    
}
