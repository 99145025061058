import { Editor, EditorState, Modifier, RichUtils } from 'draft-js';
import EmojiPicker from 'emoji-picker-react';
import { Edit, InfoCircleSolid, Sparks } from 'iconoir-react';
import React, { useEffect, useRef, useState } from 'react';
import DecoratorValidation from './DecoratorValidation';
import EditorToolbar from './EditorToolbar';
import LoaderGif from "../../asset/gif/loader.gif";

const TextEditor = ({ isAiGenerated, editorState, setEditorState, handleGenerateNewPost, postGenerating, handleOpenPopup }) => {
    const editor = useRef(null);

    const [showEmojiPicker, setShowEmojiPicker] = useState(false);

    const isStyleActive = (style) => editorState.getCurrentInlineStyle().has(style);
    // const characterCount = editorState.getCurrentContent().getPlainText('').length;
    const words = editorState.getCurrentContent().getPlainText('').split(/\s+/).filter(word => word.trim() !== '');

    const applyStyle = (style) => {
        const newState = RichUtils.toggleInlineStyle(editorState, style);
        setEditorState(newState);
    };

    const insertEmoji = (emoji) => {
        const text = emoji.emoji;
        const currentContent = editorState.getCurrentContent();
        const selectionState = editorState.getSelection();
        const newSelectionState = selectionState.merge({
            anchorOffset: selectionState.getAnchorOffset(),
            focusOffset: selectionState.getAnchorOffset(),
        });

        const contentWithEmoji = Modifier.insertText(currentContent, newSelectionState, text);
        const newEditorState = EditorState.push(editorState, contentWithEmoji, 'insert-characters');
        setEditorState(newEditorState);
    };


    const applyDecorator = (decorator) => {
        let newEditorState = EditorState.set(editorState, { decorator });
        setEditorState(newEditorState);
    }

    const removeDecorator = () => {
        const content = editorState.getCurrentContent()
        let newState = EditorState.createWithContent(content)
        setEditorState(newState)
    }

    useEffect(() => {
        editor.current.focus();
    }, []);

    return (
        <div className='flex flex-col h-full'>
            <div onClick={() => editor.current.focus()}>
                <div className='relative'>
                    <div className="bg-white z-0 relative overflow-auto h-[320px] px-[14px] pr-[100px] py-2.5 rounded-lg border border-gray-3 text-bodyThree text-space-default scrollbar" style={{ wordBreak: 'break-all' }}>
                        <Editor
                            ref={editor}
                            editorState={editorState}
                            onChange={setEditorState}
                            handleKeyCommand={(command) => {
                                const newState = RichUtils.handleKeyCommand(editorState, command);
                                if (newState) {
                                    setEditorState(newState);
                                    return 'handled';
                                }
                                return 'not handled';
                            }}
                        />
                        {
                            postGenerating && (
                                <div className='absolute top-0 left-0 w-full h-full flex flex-col items-center justify-center'>
                                    <img src={LoaderGif} alt='loading' />
                                    <p className='text-gray-5'>Generating...</p>
                                </div>
                            )
                        }
                        <div className='absolute -z-10 px-3 w-full left-0 top-[50px]'>
                            <div className='w-full h-1' style={{
                                backgroundImage: 'linear-gradient(to right, #A489F3 33%, rgba(255, 255, 255, 0) 0%)',
                                backgroundPosition: 'bottom',
                                backgroundSize: '10px 0.5px',
                                backgroundRepeat: 'repeat-x',
                            }} />
                        </div>
                        <div className='absolute right-0 px-3  top-[28px]'>
                            {/* On hover this makes visible */}
                            <div className='flex gap-1 items-center tooltip-wrapper'>
                                <p className='text-bodyThree text-star-light'>See more</p>
                                <InfoCircleSolid width={14} height={14} color='#C5C2CE' />
                                {/* Tooltip */}
                                <div className='absolute hidden tooltip bg-white p-[15px] w-[230px] rounded-lg top-5 right-0' style={{
                                    boxShadow: '0px 14px 32px 0px rgba(39, 39, 47, 0.06)'
                                }}>
                                    <p className='text-bodyThree text-gray-5'>
                                        Add a captivating <b> hook line </b> followed by a blank line to increase engagement
                                    </p>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className={`absolute text-subText text-gray-5 bottom-2 right-4 ${words.length > 400 ? 'text-mars-default' : ''}`}>
                        {words.length} words
                    </div>
                </div>
                <div className='flex justify-between max-w-full  items-center mt-2'>
                    <button className='md:hidden outlined-btn small' onClick={handleOpenPopup}>
                        <Edit />
                        Edit
                    </button>
                    <div className='flex  items-center gap-2'>
                        <button className='hidden md:flex ai-btn small' onClick={handleGenerateNewPost} disabled={isAiGenerated || postGenerating}>
                            <Sparks />
                            Write with AI
                        </button>
                        <div className="gap-1 items-center">
                            <p className="text-bodyThree text-gray-4">powered by ChatGPT</p>
                        </div>
                    </div>
                    <div className='hidden md:flex'>
                        <EditorToolbar
                            applyStyle={applyStyle}
                            showEmojiPicker={showEmojiPicker}
                            setShowEmojiPicker={setShowEmojiPicker}
                            isStyleActive={isStyleActive}
                        />
                    </div>
                </div>
            </div>
            <div className='relative'>
                {showEmojiPicker && (
                    <div className="absolute top-0 right-0 z-10">
                        <div className='w-full h-full top-0 left-0 fixed' onClick={() => { setShowEmojiPicker(false); }} />
                        <EmojiPicker
                            searchDisabled
                            onEmojiClick={insertEmoji}
                            height={300}
                        />
                    </div>
                )}
            </div>
            <div className='mt-auto'>
                <p className='text-bodyThree mt-7 md:mt-0 md:text-left text-center'>Follow the best practices for your next viral post</p>
                <DecoratorValidation applyDecorator={applyDecorator} removeDecorator={removeDecorator} editorState={editorState} />
            </div>
        </div>
    );
};

export default TextEditor;
