import React, { useState, useRef, useEffect } from 'react';

const parseContentWithHashtags = (text) => {
    const hashtagRegex = /(#\w+)/g; // Regex to match hashtags
    const parts = text.split(hashtagRegex);

    return parts.map((part, index) => {
        if (part.startsWith('#')) {
            return (
                <span key={index} className="text-star-default">
                    {part}
                </span>
            );
        }
        return part;
    });
};
const ContentExpand = ({ content, maxLines = 7, defaultValue = false, tracker = false, trackingData = {} }) => {
    const [isExpanded, setIsExpanded] = useState(true);
    const [overFlow, setOverFlow] = useState(false);
    const [needOverFlow, setNeedOverFlow] = useState(false);
    const contentRef = useRef(null);

    useEffect(() => {
        const lineHeight = parseInt(window.getComputedStyle(contentRef.current).lineHeight, 10);
        const maxHeight = lineHeight * maxLines;
        // console.log(maxHeight, contentRef.current.scrollHeight)
        if (contentRef.current.scrollHeight > maxHeight) {
            setNeedOverFlow(true);
            setOverFlow(true);
            setIsExpanded(false);
        }
        if (defaultValue) {
            setIsExpanded(true);
            setOverFlow(false);
        }
    }, [content, maxLines, defaultValue]);

    const handleClickSeeMore = () => {
        setIsExpanded(true);
        setOverFlow(false);

    };
    const handleClickSeeLess = () => {
        setIsExpanded(false);
        setOverFlow(true);
    };

    return (
        <p ref={contentRef} className="text-bodyThree text-space-dark whitespace-pre-wrap ">
            <span
                style={{
                    wordBreak: 'break-word',
                    ...(!isExpanded && {
                        display: '-webkit-box',
                        WebkitLineClamp: maxLines,
                        WebkitBoxOrient: 'vertical',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    }),
                }}
            >
                {parseContentWithHashtags(content)}
            </span>
            {
                needOverFlow && (
                    <>
                        {overFlow ? (
                            <span
                                onClick={handleClickSeeMore}
                                className="text-star-light cursor-pointer hover:text-star-default"
                            >
                                See more
                            </span>
                        ) : (
                            <span
                                onClick={handleClickSeeLess}
                                className="text-star-light block cursor-pointer hover:text-star-default"
                            >
                                See less
                            </span>
                        )}
                    </>
                )
            }
        </p>
    );
};

export default ContentExpand;
