import { useEffect, useState } from "react";
import CommentScreen from "./CommentScreen";
import WritingScreen from "./WritingScreen";
import PreviewScreen from "./PreviewScreen";
import { ContentState, EditorState } from "draft-js";
import { generatePost } from "../../service/genai.service";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import Header from "../../layout/Header";
import { Link, useLocation } from "react-router-dom";
import RestrictPopup from "../../component/RestrictPopup";

const RecreatePost = () => {
    const location = useLocation();
    const props = location.state;
    const [currentScreen, setCurrenScreen] = useState(0);
    const [selectedComment, setSelectedComment] = useState([]);
    const [openPopup, setOpenPopup] = useState(false);

    const [editorState, setEditorState] = useState(() =>
        EditorState.createEmpty()
    );

    const [isAiGenerated, setIsAiGenerated] = useState(false);

    const { mutate, isPending: postGenerating } = useMutation({
        mutationFn: generatePost,
        onSuccess: (data) => {
            const contentState = ContentState.createFromText(data?.data?.content || "");
            setEditorState(EditorState.createWithContent(contentState));
        },
        onError: (error) => {
            setOpenPopup(true);
        }
    });

    const handleCheckClicked = (comment) => {
        setSelectedComment((prev) => {
            const isSelected = prev.some(selected => selected._id === comment._id);

            if (isSelected) {
                return prev.filter(selected => selected._id !== comment._id);
            } else {
                return [...prev, comment];
            }
        });
    };

    const handleNext = () => {
        setCurrenScreen((prev) => prev + 1);
    }

    const handleBack = () => {
        setCurrenScreen((prev) => prev - 1);
    }

    const handleGenerateNewPost = () => {
        setIsAiGenerated(true);
        const { content } = props;
        let comments = '';
        selectedComment.forEach((comment, index) => {
            comments += `${index + 1}: ${comment.commentText}\n`; // Adding index properly
        });

        mutate({ content, comments, tag: props?.postType }); // Pass content and selected comments to mutation
    };

    const handleWriteMannual = () => {
        setIsAiGenerated(false);
        setEditorState(EditorState.createEmpty())
    }
    const steps = [
        <CommentScreen {...props}
            handleGenerateNewPost={handleGenerateNewPost}
            handleWriteMannual={handleWriteMannual}
            selectedComment={selectedComment}
            handleCheckClicked={handleCheckClicked}
            handleNext={handleNext}
        />,
        <WritingScreen {...props}
            handleGenerateNewPost={handleGenerateNewPost}
            editorState={editorState}
            postGenerating={postGenerating}
            isAiGenerated={isAiGenerated}
            setEditorState={setEditorState}
            handleWriteMannual={handleWriteMannual}
            selectedComment={selectedComment}
            handleNext={handleNext}
            handleBack={handleBack}
        />,
        <PreviewScreen {...props}
            editorState={editorState}
            selectedComment={selectedComment}
            handleNext={handleNext}
            handleBack={handleBack}
        />
    ];

    return (
        <>
            {
                openPopup && <RestrictPopup onClose={() => setOpenPopup(false)} />
            }
            <div className="w-full h-full">
                <Header />
                <p className="hidden md:block text-center  mt-9 mb-4 text-subHeadingThreeBold text-space-dark">Your <span className="text-star-default">free</span> LinkedIn content inspiration</p>
                <div className="w-[100%] md:w-[90%] md:rounded-2xl md:overflow-hidden mx-auto">
                    {steps[currentScreen]}
                </div>
                <div className="hidden md:flex items-center my-4 pl-20">
                    <p className="text-bodyTwo text-gray-5">Wish to select another post or change topic for inspiration? </p>
                    <Link to={-1} className="text-btn small">Click here</Link>
                </div>
            </div>
        </>
    )

}

export default RecreatePost;