import React, { useEffect, useState } from 'react';
import LogoIcon from '../asset/svg/LogoIcon';
import CrossIcon from '../asset/svg/CrossIcon';
import ToogleOFF from '../asset/svg/ToggleOff';
import ToogleON from '../asset/svg/ToggleOn';
import { links } from '../constent/links';
import useResponsive from '../hook/useResponsive';
import WhiteLogo from '../asset/svg/WhiteLogo';

const CookiePrefrence = () => {
  const { isMobile } = useResponsive();
  const [isOpen, setIsOpen] = useState(false);
  const [cookieIsPresent, setCookieIsPresent] = useState(true);
  const [cookies, setCookies] = useState({
    strict: true,
    functional: false,
    performance: false,
    targeting: false,
  });

  // Load state from localStorage on component mount
  useEffect(() => {
    const storedCookies = localStorage.getItem('cookies');
    if (storedCookies) {
      setCookieIsPresent(true);
      setCookies(JSON.parse(storedCookies)); // Parse and set the state from localStorage
    }else{
      setCookieIsPresent(false);
    }
  }, []);

  const HandleManageCookiePopup = () => {
    setIsOpen(isOpen ? false : true);
  }

  const acceptCookies = (type = 'all') => {

    let newCookies = cookies;
    if (type === 'all') {
      newCookies = {
        strict: true, functional: true, performance: true, targeting: true, method: 'acceptAll'
      }
    }else if(type === 'essential'){
      newCookies = {
        ...cookies,
        method:'essential'
      }
    }
   
    localStorage.setItem('cookies', JSON.stringify(newCookies));
    setCookies(newCookies);
    setCookieIsPresent(true);
  }

  if (cookieIsPresent) {
    return <></>
  }
  return (
    <>
      {
        isOpen ? (
          <div className="fixed inset-0 flex items-center justify-center bg-custom-light backdrop-blur-sm bg-opacity-50 z-50">
            <div className="bg-gray-moon w-full md:w-[90%] max-h-screen max-w-md sm:max-w-lg md:max-w-3xl rounded-3xl shadow-lg text-gray-700">

              <div className="flex md:flex-row py-5 px-5 sm:px-10 md:px-20 rounded-t-3xl justify-between bg-hover-gradient-1 items-center">
                {isMobile ? <WhiteLogo /> : <LogoIcon type="white" width={"111px"} />}
                <h3 className="font-bold text-star-light4 font-sans text-center mt-2 md:mt-0">Manage Cookies</h3>
                <span className="bg-gray-3 bg-transparent cursor-pointer rounded-full w-10 h-10 md:w-12 md:h-12 flex items-center justify-center" onClick={HandleManageCookiePopup}>
                  <CrossIcon />
                </span>
              </div>

              <div className="px-4 sm:px-6 md:px-[93px] h-[60vh] md:h-[70vh] scrollbar overflow-y-auto ">
                <p className="text-sm mb-6 mt-4 md:mt-[17px] text-xs leading-5">
                  When a user visits an online website, a small text file that may contain a unique identifier is sent by a web server to the browser on the user's computer, mobile phone, or any other internet-enabled device. This small text file is termed as a "cookie". Cookies and other similar technologies help the websites to work efficiently and collect information about the online preferences of the user. Hereafter, these technologies may be referred to collectively as "cookies". Electronic images known as 'web beacons' (also known as clear gifs, tags, or pixels) may be present on some of our website pages that allows us to count users who have visited our pages and run analytics. Web beacons are responsible for collecting limited information e.g., a cookie number, time and date of a page view, and description of the page on which it resides. Please note that Web Beacons placed by third-party advertisers might be present on our website. <br /><br />

                  Because we respect your right to privacy, you can choose not to allow some types of cookies. Click on the different category headings to find out more and change our default settings.
                </p>

                <div className='space-y-4'>

                  <div className="bg-star-light3 p-4 md:p-5 rounded-[20px] ">
                    <div className='flex justify-between items-center mb-2'>
                      <h4 className="font-bold bg-hover-gradient-4 text-transparent bg-clip-text">Strictly Necessary Cookies</h4>
                      <span className='mb-0 md:mb-[-75px] cursor-pointer'>
                        {
                          cookies.strict ? <ToogleON /> : <ToogleOFF />
                        }
                      </span>
                    </div>
                    <div className="flex flex-col md:flex-row justify-between items-start md:items-center">
                      <p className="text-xs md:text-sm max-w-md md:w-11/12 leading-5 text-gray-600 mb-2 md:mb-0">
                        These Cookies allow us to count visits and traffic sources so we can measure and improve the performance of our Platform. They help us to know which pages are the most and least popular among visitors.
                      </p>
                    </div>
                  </div>
                  <div className="bg-star-light3 p-4 md:p-5 rounded-[20px]">
                    <div className='flex justify-between items-center mb-2'>
                      <h4 className="font-bold bg-hover-gradient-4 text-transparent bg-clip-text ">Functional Cookies</h4>
                      <span className='mb-0 md:mb-[-75px] cursor-pointer' onClick={() => {
                        setCookies(prevCookies => ({
                          ...prevCookies,
                          functional: !prevCookies.functional
                        }))
                      }}>
                        {
                          cookies.functional ? <ToogleON /> : <ToogleOFF />
                        }
                      </span>
                    </div>
                    <div className="flex flex-col md:flex-row justify-between items-start md:items-center">
                      <p className="text-xs md:text-sm max-w-md md:w-11/12 leading-5 text-gray-600 mb-2 md:mb-0">
                        These cookies enable the website to provide enhanced functionality and personalization. They may be set by us or by third-party providers whose services we have added to our pages. If you do not allow these cookies, then some or all these services may not function properly.
                      </p>

                    </div>
                  </div>
                  <div className="bg-star-light3 p-4 md:p-5 rounded-[20px]">
                    <div className='flex justify-between items-center mb-2'>
                      <h4 className="font-bold bg-hover-gradient-4 text-transparent bg-clip-text">Performance Cookies</h4>
                      <span className='mb-0 md:mb-[-75px] cursor-pointer' onClick={() => {
                        setCookies(prevCookies => ({
                          ...prevCookies,
                          performance: !prevCookies.performance
                        }))
                      }}>
                        {
                          cookies.performance ? <ToogleON /> : <ToogleOFF />
                        }
                      </span>
                    </div>
                    <div className="flex flex-col md:flex-row justify-between items-start md:items-center">
                      <p className="text-xs md:text-sm max-w-md md:w-11/12 leading-5 text-gray-600 mb-2 md:mb-0">
                        These Cookies allow us to count visits and traffic sources so we can measure and improve the performance of our site. They help us to know which pages are the most and least popular and see how visitors move around the site. All information these cookies collect is aggregated and therefore anonymous in nature. If you do not allow these Cookies, we will not know when you have visited our website and will not be able to monitor its performance.
                      </p>

                    </div>
                  </div>
                  <div className="bg-star-light3 p-4 md:p-5 rounded-[20px]">
                    <div className='flex justify-between items-center mb-2'>
                      <h4 className="font-bold bg-hover-gradient-4 text-transparent bg-clip-text">Targeting Cookies</h4>
                      <span className='mb-0 md:mb-[-75px] cursor-pointer' onClick={() => {
                        setCookies(prevCookies => ({
                          ...prevCookies,
                          targeting: !prevCookies.targeting
                        }))
                      }}>
                        {
                          cookies.targeting ? <ToogleON /> : <ToogleOFF />
                        }
                      </span>
                    </div>
                    <div className="flex flex-col md:flex-row justify-between items-start md:items-center">
                      <p className="text-xs md:text-sm max-w-md md:w-11/12 leading-5 text-gray-600 mb-2 md:mb-0">
                        These Cookies may be set through our site by our advertising partners. They may be used by those companies to build a profile of your interests and show you relevant adverts on other sites. If you do not allow these Cookies, you will experience less targeted advertising.
                      </p>

                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-6 px-4 sm:px-10 md:px-20 pb-4 md:pb-[24px] flex flex-col md:flex-row justify-between space-y-3 md:space-y-0">
                <button className="ai-btn border-none bg-star-light3 w-full md:w-auto small flex justify-center md:regular mr-0 md:mr-2" onClick={HandleManageCookiePopup}>Back</button>
                <button className="filled-btn w-full md:w-auto small md:regular mr-0 md:mr-2" onClick={() => acceptCookies('all')}>Accept all cookies</button>
                <button className="filled-btn w-full md:w-auto small md:regular" onClick={() => acceptCookies('essential')}>Accept only essential cookies</button>
              </div>

            </div>
          </div>
        ) : (
          <div className="fixed left-0 bottom-0 w-full bg-star-light4 p-4 md:py-6 md:px-8 rounded-t-3xl z-[99] ">
            <span className="text-bodyThree font-medium md:text-bodyOne flex flex-wrap gap-1 text-gray-5 ">
              This sites uses cookies. Visit our
              <a href={links.cookiePolicy} className="text-star-dark underline ">cookies policy page</a>
              or click the link in any footer for more information and to change your preferences.
            </span>

            <div className="flex flex-col md:flex-row justify-end items-center mt-4 gap-3">
              <button
                className={`outlined-btn light border-none ${isMobile ? 'small w-full' : ''}`}
                onClick={HandleManageCookiePopup}
              >
                Manage my cookies
              </button>
              <button className={`filled-btn ${isMobile ? 'small w-full' : ''}`} onClick={() => acceptCookies('all')}>Accept all cookies</button>
              <button className={`filled-btn ${isMobile ? 'small w-full' : ''}`} onClick={() => acceptCookies('essential')}>Accept only essential cookies</button>
            </div>
          </div>
        )
      }
    </>
  )
};

export default CookiePrefrence;
