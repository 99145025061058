const PricingMobileTwo = () => {
    return (
        <div>
            <svg width="360" height="744" viewBox="0 0 360 744" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M-91.1959 6.30664L305.741 188.884C334.392 202.063 342.191 239.163 321.27 262.762L278.261 311.277C268.404 322.395 269.384 339.388 280.453 349.301L715.124 738.57" stroke="url(#paint0_linear_29_110)" stroke-width="12" />
                <defs>
                    <linearGradient id="paint0_linear_29_110" x1="-420.356" y1="-101.263" x2="-9.12945" y2="1091.93" gradientUnits="userSpaceOnUse">
                        <stop offset="0.0614042" stop-color="#5F45FD" />
                        <stop offset="0.395" stop-color="#FDA1F5" />
                        <stop offset="0.725" stop-color="#FBDEBC" />
                        <stop offset="1" stop-color="#5F45FD" />
                    </linearGradient>
                </defs>
            </svg>

            <svg width="360" height="915" viewBox="0 0 360 915" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M415.608 6.08728L92.7264 60.7168C37.1405 70.1216 20.9447 142.208 67.1657 174.486L280.749 323.639C308.829 343.248 314.367 382.566 292.798 409.169L-261.538 1092.88" stroke="url(#paint0_linear_29_112)" stroke-width="12" />
                <defs>
                    <linearGradient id="paint0_linear_29_112" x1="-135.397" y1="1306.77" x2="-456.641" y2="-152.788" gradientUnits="userSpaceOnUse">
                        <stop offset="0.0614042" stop-color="#5F45FD" />
                        <stop offset="0.61989" stop-color="#FDA1F5" />
                        <stop offset="0.77571" stop-color="#FBDEBC" />
                        <stop offset="0.909986" stop-color="#5F45FD" />
                    </linearGradient>
                </defs>
            </svg>
            <svg width="360" height="1004" viewBox="0 0 360 1004" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M-32.3403 6.4707L7.61892 17.574C69.7328 34.8333 94.0892 109.886 53.9468 160.33L-52.4181 293.991C-103.702 358.435 -74.1203 454.287 4.56276 478.621L441.174 613.65L-111.523 998.387" stroke="url(#paint0_linear_29_108)" stroke-width="12" />
                <defs>
                    <linearGradient id="paint0_linear_29_108" x1="-384.861" y1="6.12319" x2="-107.053" y2="857.442" gradientUnits="userSpaceOnUse">
                        <stop offset="0.0614042" stop-color="#5F45FD" />
                        <stop offset="0.395" stop-color="#FDA1F5" />
                        <stop offset="0.725" stop-color="#FBDEBC" />
                        <stop offset="1" stop-color="#5F45FD" />
                    </linearGradient>
                </defs>
            </svg>

        </div>
    )
}
export default PricingMobileTwo;