const HomeLineThree = ({ className }) => {
    return (
        <svg className={className} width="898" height="1875" viewBox="0 0 898 1875" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M537.5 2776L478.189 2445.48C476.4 2435.51 475.5 2425.41 475.5 2415.28V1898.57C475.5 1795.69 412.476 1703.31 316.684 1665.79L185.833 1614.53C169.703 1608.21 154.619 1599.5 141.09 1588.68L54.9934 1519.84C-5.92035 1471.13 6.00677 1375.29 77 1343V1343L602.544 1026.27C677.607 981.032 723.5 899.791 723.5 812.15V465.333C723.5 399.543 749.434 336.404 795.678 289.608L1071 11" stroke="url(#paint0_linear_631_17334)" stroke-width="30" />
            <defs>
                <linearGradient id="paint0_linear_631_17334" x1="839.5" y1="570.228" x2="-1095.09" y2="2311.48" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#5F45FD" />
                    <stop offset="0.395" stopColor="#FDA1F5" />
                    <stop offset="0.725" stopColor="#FBDEBC" />
                    <stop offset="1" stopColor="#5F45FD" />
                </linearGradient>
            </defs>
        </svg>

    )
}
export default HomeLineThree;