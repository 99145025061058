const MobileHomeLine = ({className}) => {
    return (
        <svg className={className} width="360" height="5507" viewBox="0 0 360 5507" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_2_379)">
                <rect width="360" height="5507" fill="white" />
                <path d="M-89.2734 470.717L264.621 384.534C285.241 379.513 307.026 383.979 324.007 396.709L443.117 486" stroke="url(#paint0_linear_2_379)" stroke-width="12" />
                <path d="M411.685 753.664L259.223 840.697C224.902 860.289 213.968 904.619 235.242 937.924V937.924C270.698 993.432 255.394 1067.09 200.76 1103.88L-11.3182 1246.69" stroke="url(#paint1_linear_2_379)" stroke-width="12" />
                <path d="M-32.3403 1323.47L7.61892 1334.57C69.7328 1351.83 94.0892 1426.89 53.9468 1477.33L-52.4181 1610.99C-103.702 1675.44 -74.1203 1771.29 4.56276 1795.62L441.174 1930.65L-111.523 2315.39" stroke="url(#paint2_linear_2_379)" stroke-width="12" />
                <path d="M-91.1959 2279.31L305.741 2461.88C334.392 2475.06 342.191 2512.16 321.27 2535.76L278.261 2584.28C268.404 2595.4 269.384 2612.39 280.453 2622.3L715.124 3011.57" stroke="url(#paint3_linear_2_379)" stroke-width="12" />
                <path d="M-91.1965 4803.31L453.185 4998.86C486.896 5010.97 495.212 5054.83 468.273 5078.44L184.612 5327.03C170.9 5339.05 172.794 5360.92 188.368 5370.4L924.949 5818.8" stroke="url(#paint4_linear_2_379)" stroke-width="12" />
                <path d="M415.608 3013.09L92.7264 3067.72C37.1405 3077.12 20.9447 3149.21 67.1657 3181.49L280.749 3330.64C308.829 3350.25 314.367 3389.57 292.798 3416.17L-261.538 4099.88" stroke="url(#paint5_linear_2_379)" stroke-width="12" />
                <path d="M10.9154 -19.7462L42.9799 178.29C48.9155 214.949 3.17974 236.523 -21.3362 208.629L-51.4971 174.312" stroke="url(#paint6_linear_2_379)" stroke-width="12" />
                <path d="M-9.4099 3953.9L34.1693 4056.25C55.5083 4106.36 -1.17575 4153.68 -46.6746 4123.73L-74.124 4105.67" stroke="url(#paint7_linear_2_379)" stroke-width="12" />
            </g>
            <defs>
                <linearGradient id="paint0_linear_2_379" x1="-207.722" y1="222.204" x2="432.839" y2="68.084" gradientUnits="userSpaceOnUse">
                    <stop offset="0.0614042" stop-color="#5F45FD" />
                    <stop offset="0.395" stop-color="#FDA1F5" />
                    <stop offset="0.725" stop-color="#FBDEBC" />
                    <stop offset="1" stop-color="#5F45FD" />
                </linearGradient>
                <linearGradient id="paint1_linear_2_379" x1="785.227" y1="753.664" x2="404.94" y2="1729.94" gradientUnits="userSpaceOnUse">
                    <stop offset="0.0614042" stop-color="#5F45FD" />
                    <stop offset="0.395" stop-color="#FDA1F5" />
                    <stop offset="0.725" stop-color="#FBDEBC" />
                    <stop offset="1" stop-color="#5F45FD" />
                </linearGradient>
                <linearGradient id="paint2_linear_2_379" x1="-384.861" y1="1323.12" x2="-107.053" y2="2174.44" gradientUnits="userSpaceOnUse">
                    <stop offset="0.0614042" stop-color="#5F45FD" />
                    <stop offset="0.395" stop-color="#FDA1F5" />
                    <stop offset="0.725" stop-color="#FBDEBC" />
                    <stop offset="1" stop-color="#5F45FD" />
                </linearGradient>
                <linearGradient id="paint3_linear_2_379" x1="-420.356" y1="2171.74" x2="-9.12945" y2="3364.93" gradientUnits="userSpaceOnUse">
                    <stop offset="0.0614042" stop-color="#5F45FD" />
                    <stop offset="0.395" stop-color="#FDA1F5" />
                    <stop offset="0.725" stop-color="#FBDEBC" />
                    <stop offset="1" stop-color="#5F45FD" />
                </linearGradient>
                <linearGradient id="paint4_linear_2_379" x1="-420.356" y1="4695.74" x2="-9.12984" y2="5888.93" gradientUnits="userSpaceOnUse">
                    <stop offset="0.0614042" stop-color="#5F45FD" />
                    <stop offset="0.395" stop-color="#FDA1F5" />
                    <stop offset="0.725" stop-color="#FBDEBC" />
                    <stop offset="1" stop-color="#5F45FD" />
                </linearGradient>
                <linearGradient id="paint5_linear_2_379" x1="-135.397" y1="4313.77" x2="-456.641" y2="2854.21" gradientUnits="userSpaceOnUse">
                    <stop offset="0.0614042" stop-color="#5F45FD" />
                    <stop offset="0.61989" stop-color="#FDA1F5" />
                    <stop offset="0.77571" stop-color="#FBDEBC" />
                    <stop offset="0.909986" stop-color="#5F45FD" />
                </linearGradient>
                <linearGradient id="paint6_linear_2_379" x1="-19.7133" y1="116.692" x2="835.313" y2="616.648" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#5F45FD" />
                    <stop offset="0.395" stop-color="#FDA1F5" />
                    <stop offset="0.725" stop-color="#FBDEBC" />
                    <stop offset="1" stop-color="#5F45FD" />
                </linearGradient>
                <linearGradient id="paint7_linear_2_379" x1="178.653" y1="3718.89" x2="288.784" y2="4142.03" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#5F45FD" />
                    <stop offset="0.395" stop-color="#FDA1F5" />
                    <stop offset="0.792211" stop-color="#FBDEBC" />
                    <stop offset="1" stop-color="#5F45FD" />
                </linearGradient>
                <clipPath id="clip0_2_379">
                    <rect width="360" height="5507" fill="white" />
                </clipPath>
            </defs>
        </svg>

    )
}
export default MobileHomeLine;