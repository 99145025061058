import React, { act, useEffect, useRef, useState } from "react";
import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Check, LensPlus, LightBulbOn, Search } from "iconoir-react";
import LogoIcon from "../../asset/svg/LogoIcon";
import TutorialImage1 from '../../asset/image/tutorial-1.png';
import TutorialImage2 from '../../asset/image/tutorial-2.png';
import TutorialImage3 from '../../asset/image/tutorial-3.png';

gsap.registerPlugin(ScrollTrigger);

const steps = [
    {
        icon: <Search />,
        title: <><b>Find</b> what works</>,
        subtitle: <><b>Our engine</b> will find the most relevant posts for you</>,
        img: TutorialImage1,
        list: [
            'You can repurpose it',
            'You can add a new twist to it',
        ]
    },
    {
        icon: <LensPlus />,
        title: <><b>Add</b> your insights</>,
        subtitle: <><b>Our engine</b> will find the most relevant posts for you</>,
        img: TutorialImage2,
        list: [
            'Your unique POV',
            'Your conclusion',
            'Your experiences'
        ]
    },
    {
        icon: <LightBulbOn />,
        title: <><b>Generate</b> your viral post</>,
        subtitle: <><b>Our engine</b> will find the most relevant posts for you</>,
        img: TutorialImage3,
        list: [
            'Ask our AI to build the final version',
            'Write it yourself',
            'Do a mixture of both'
        ]
    },
];

const HomeTutorial = () => {
    const [activeIndex, setActiveIndex] = useState(0);
    const container = useRef(null);

    useEffect(() => {
        const sections = gsap.utils.toArray('.progress-section');

        // Create ScrollTriggers for each section
        sections.forEach((section, index) => {
            const progressBar = document.querySelector(`#progress-${index}`);

            // Set initial height of the progress bar
            gsap.set(progressBar, { height: 0 });

            ScrollTrigger.create({
                trigger: section,
                start: 'top center',
                end: 'bottom center',
                scrub: true,
                snap: {
                    snapTo: 0.9, // Divide equally for each section
                    duration: 0, // Snap duration
                    ease: "power1.inOut",
                },
                onEnter: () => setActiveIndex(index),
                onLeaveBack: () => setActiveIndex(index !== 0 ? index - 1 : 0),
                onUpdate: (self) => {
                    gsap.to(progressBar, {
                        height: `${self.progress * 100}%`,
                        duration: 0.2,
                        ease: 'power1.out',
                    });
                },
                onEnterBack: (self) => {
                    gsap.to(progressBar, { height: `${self.progress * 100}%`, duration: 0.2 });
                }
            });
        });

        // Cleanup ScrollTriggers on component unmount
        return () => ScrollTrigger.getAll().forEach(trigger => trigger.kill());
    }, []);

    return (
        <div className="p-2 md:pl-20 md:p-0 relative">
            <div id="container" className={`md:h-[2300px]`} ref={container}>
                {/* Top Progress Display */}
                <div className="sticky top-24">
                    <div className="flex flex-wrap text-center items-center justify-center gap-4 mb-5 md:mb-16">
                        <p className="text-headingThree md:text-subHeadingOne">Go Viral, your way with SuperPen</p>
                       
                    </div>

                    {/* Sidebar Progress Bars */}
                    <div className="flex md:gap-6 w-full items-center">
                        <div className="flex flex-1 gap-4">
                            <div className="hidden md:block">

                                <div className="flex w-3 h-full relative bg-[#FCFBFF] rounded-xl">
                                    <div className={`rounded-xl absolute w-full h-[200px] bg-star-default transition-all duration-500`} style={{ top: `${activeIndex * 150}px` }} />
                                </div>
                            </div>
                            <div className="flex flex-1 flex-col relative">
                                {steps.map((item, index) => {
                                    const isActive = index === activeIndex;
                                    const checked = index < activeIndex;
                                    const notVisited = index > activeIndex;
                                    return (
                                        <div key={index} id={`progress-section-${index}`} className={`relative ${index !== 2 ? 'pb-16' : ''}`}>
                                            <div className="rounded-[20px] bg-star-light4 relative p-3 md:pt-9 md:pr-6 md:pb-[21px] md:pl-[71px]">
                                                <div className={`${notVisited ? 'bg-star-light2' : 'bg-base-gradient'} absolute z-30 -top-[18px] left-[8px] md:left-[22px] p-2 gap-2 flex rounded-xl text-white text-subTextBold md:text-bodyTwoBold`}>
                                                    {checked ? <Check /> : item.icon} <span>STEP {index + 1}</span>
                                                </div>
                                                <div className="pl-[50px] pt-3 md:p-0">
                                                    <p className={`${notVisited ? 'text-star-light' : 'text-star-default'} text-subHeadingTwo`}>{item.title}</p>
                                                    <div className={`${isActive ? '' : 'md:hidden'}`}>
                                                        <p className="text-bodyTwo text-gray-5">{item.subtitle}</p>
                                                        <ul className="list-disc mt-[13px]">
                                                            {item.list.map((listItem, indx) => (
                                                                <li className="text-bodyOne" key={indx}>{listItem}</li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="md:hidden relative mt-4 z-30 w-full h-[240px]">
                                                    <img src={item.img} alt="demo-img" className="w-full h-full object-contain"/>
                                                </div>
                                            </div>
                                            <div className="block md:hidden">
                                                
                                                {/* Progress bar container */}
                                                <div className="absolute h-full w-[3px] bg-star-light2 z-10 top-0 left-[21px] md:left-[41px]">
                                                    <div className="w-full bg-base-gradient rounded-lg h-0" id={`progress-${index}`} />
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>

                        {/* Placeholder image section */}
                        <div className="hidden md:inline-block flex-1 ">
                            <img src={steps[activeIndex].img} alt="demo-img" />
                        </div>
                    </div>
                </div>

                {/* Full-page sections for ScrollTrigger */}
                <div className="absolute top-0 left-0">
                    {steps.map((item, index) => (
                        <div
                            key={index}
                            className={`progress-section h-[684px] flex items-center justify-center text-3xl invisible`}
                            style={{
                                backgroundColor: index % 2 === 0 ? '#f0f0f0' : '#e0e0e0',
                            }}
                        >
                            Section {index + 1}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default HomeTutorial;
