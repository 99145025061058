const WhiteLogo=()=>{
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="27" height="26" viewBox="0 0 27 26" fill="none">
  <g clip-path="url(#clip0_96_2717)">
    <path d="M4.84962 0C4.69395 -1.2546e-06 4.54067 0.0375987 4.40335 0.109474C4.26603 0.18135 4.1489 0.285286 4.06231 0.412091L0.160063 6.12676C0.0560318 6.27912 0.00033872 6.45825 1.54013e-06 6.64158C-0.00033564 6.82491 0.0546982 7.00424 0.158168 7.15696L12.7064 25.6767C12.737 25.7219 12.7828 25.7552 12.8358 25.7707C12.8889 25.7863 12.9458 25.7832 12.9967 25.7619C13.0476 25.7406 13.0893 25.7025 13.1145 25.6542C13.1398 25.6059 13.147 25.5505 13.1349 25.4976L9.64146 10.1899C9.61332 10.0666 9.61114 9.93894 9.63507 9.81479C9.659 9.69064 9.70853 9.57259 9.78063 9.46785L14.6484 2.39518C14.6916 2.33247 14.7497 2.28104 14.8178 2.24537C14.8859 2.2097 14.9618 2.19088 15.039 2.19053C15.1162 2.19019 15.1922 2.20833 15.2606 2.24339C15.329 2.27844 15.3877 2.32935 15.4314 2.39167L20.3519 9.40165C20.4259 9.50716 20.4769 9.62655 20.5017 9.75232C20.5264 9.87808 20.5243 10.0075 20.4955 10.1324L18.6265 18.2484C18.6143 18.3013 18.6214 18.3567 18.6466 18.405C18.6717 18.4533 18.7133 18.4915 18.7642 18.5128C18.815 18.5342 18.8719 18.5375 18.925 18.522C18.978 18.5066 19.0239 18.4734 19.0546 18.4283L26.7206 7.15734C26.8245 7.00456 26.8799 6.82503 26.8797 6.64142C26.8796 6.45781 26.8239 6.27837 26.7197 6.12579L22.8181 0.412086C22.7315 0.285287 22.6144 0.181355 22.4771 0.109481C22.3398 0.0376062 22.1865 4.34547e-06 22.0308 0L4.84962 0Z" fill="#F7F6FF"/>
    <path d="M14.7283 9.06979L14.966 8.20447L15.2037 9.06979C15.2829 9.35796 15.4378 9.6207 15.6531 9.83173C15.8684 10.0427 16.1365 10.1947 16.4305 10.2722L17.3133 10.5052L16.4305 10.7382C16.1365 10.8158 15.8684 10.9677 15.6531 11.1787C15.4378 11.3898 15.2829 11.6525 15.2037 11.9407L14.966 12.806L14.7283 11.9407C14.6491 11.6525 14.4942 11.3898 14.2789 11.1787C14.0636 10.9677 13.7955 10.8158 13.5015 10.7382L12.6187 10.5052L13.5015 10.2722C13.7955 10.1947 14.0636 10.0428 14.2789 9.83173C14.4942 9.62071 14.6491 9.35796 14.7283 9.06979Z" fill="#F7F6FF"/>
  </g>
  <defs>
    <clipPath id="clip0_96_2717">
      <rect width="27" height="26" fill="white"/>
    </clipPath>
  </defs>
</svg>
    )
}

export default WhiteLogo;