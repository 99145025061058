import moment from "moment/moment";
import BadgeCheckFilled from "../asset/svg/BadgeCheckFilledIcon";
import SoloLogo from "../asset/svg/SoloLogo";
import { links } from "../constent/links";

const PlanCard = (props) => {

    const {
        description,
        color = '#2789EC',
        background = '#F0FAFF',
        name,
        billingPeriod,
        amount,
        amountBeforeDiscount,
        features,
        best,
        isFreeTrail
    } = props;


    return (
        <div className={`${best ? 'mt-12' : ''} md:mt-0 relative border flex flex-col rounded-[20px] px-[26px] py-9`} style={{ borderColor: color, background: background }}>
            {best && <div className="absolute -top-[50px] left-0 right-0 w-full -z-10 rounded-[20px] text-white text-center bg-star-default  pt-4 py-10">
                <p className="text-bodyOneBold md:text-headingFour">Best Value 🚀
                </p>
            </div>}
            {/* <div className="absolute -top-[16px] right-0 w-[214px] h-[207px]">
                <SoloLogo className={'w-full h-full'} />
            </div> */}
            <p className="text-headingThree " style={{ color: color }}>{name}</p>
            <p className="text-bodyTwo text-gray-6">{description}</p>
            <p className="text-subHeadingTwo font-semibold text-gray-5 mt-8"> <del>{amountBeforeDiscount}</del></p>
            <div className="flex items-center gap-2">
                <p className="text-headingTwo text-space-default">{amount}  </p>
                <span className="text-bodyTwo text-gray-5">{billingPeriod}</span>
            </div>
            <p className="text-bodyOne text-gray-5">+ Taxes</p>
            <div className="mt-5">
                <p className="text-bodyTwoBold " style={{ color: color }}>Get started with</p>
                <div className="flex mt-4 flex-col gap-[26px]">
                    {
                        features?.map((item, index) => (
                            <div key={index} className="flex items-start gap-2">
                                <BadgeCheckFilled color={color} />
                                <div>
                                    <p className="text-subHeadingThree text-gray-6">{item.title}</p>
                                    <p className="text-bodyTwo text-gray-5">{item.description}</p>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>

            {isFreeTrail ? (
                <div className="flex flex-col pt-5 items-center justify-center mt-auto">
                    <a href={links.sigup} target="_blank" rel="noreferrer" className={`filled-btn ${color === '#2789EC' ? 'blue' : ''} `}>Start FREE Trial</a>
                    <p className="text-bodyThree text-center text-gray-5 mt-2.5">Start your 7 Days free trial & get access to all premium features
                    </p>
                </div>
            ) : (
                <div className="flex items-center justify-center mt-5">
                    <p className="text-button text-center" style={{ color: '#FF90F6' }}><span className="font-semibold">Contact us</span>  <br /> <span className="font-bold">hello@superpen.io</span></p>
                </div>
            )}
        </div>
    )
}
export default PlanCard;