import { BrightStar, Community, LotOfCash, Suitcase, UserBag } from "iconoir-react";
import LinkedinIcon from "../../asset/svg/LinkedinIcon";
import { useEffect, useRef, useState } from "react";
import TutorialImage from '../../asset/image/home-journey.png';

const list = [
    {
        icon: <Suitcase />,
        title: 'Get a high paying job',
        image: TutorialImage,
    },
    {
        icon: <LotOfCash />,
        title: 'Land a freelancing client',
        image: TutorialImage,
    },
    {
        icon: <LotOfCash />,
        title: 'Raise funds for a startup',
        image: TutorialImage,
    },
    {
        icon: <BrightStar />,
        title: 'Get a Brand Deal',
        image: TutorialImage,
    },
    {
        icon: <UserBag />,
        title: 'Find a co-founder',
        image: TutorialImage,
    },
    {
        icon: <Community />,
        title: 'Build a crazy team',
        image: TutorialImage,
    },
];

const HomeJourney = () => {
    const [activeIndex, setActiveIndex] = useState(null);
    const instance = useRef(null);

    // useEffect(() => {
    //     instance.current = setTimeout(() => {
    //         setActiveIndex((prev) => (prev + 1) % list.length);
    //     }, 1500);

    //     return () => {
    //         clearTimeout(instance.current);
    //     };
    // }, [activeIndex]); 

    return (
        <div className="container mx-auto">
            <div className="text-headingThree md:text-headingTwo flex-wrap items-center justify-center flex gap-1 md:gap-2.5 text-space-dark text-center">
                <span className="text-[#0A66C2]">Linked </span>
                <LinkedinIcon className={'w-[30px] h-[30px] md:w-[40px] md:h-[40px]'} />
                is the only platform
            </div>
            <p className="text-bodyOne md:text-subHeadingTwo text-gray-5 text-center">where you can...</p>
            <div className="mt-4 md:mt-16">
                <div className="rounded-xl p-2.5 bg-star-light4 md:bg-transparent md:rounded-none md:p-0 flex flex-col-reverse md:flex-row gap-2 md:gap-6 h-min">
                    <div className="md:w-[55%] rounded-[20px] overflow-hidden  md:h-[510px]">
                        <img className="w-full h-full object-contain" src={TutorialImage} alt={'dummy-img'} />
                    </div>
                    <div className="flex md:w-[45%] flex-col gap-1 md:gap-4 md:pr-20">
                        {list.map((item, index) => {
                            const isActive = index === activeIndex;
                            return (
                                <div key={index} className={`rounded-lg md:rounded-2xl flex gap-3 md:gap-6 items-center p-2 md:p-4 ${isActive ? 'bg-star-light2 md:bg-star-light4' : ''}`}>
                                    <div className={`rounded-xl p-1 md:p-2 flex items-center justify-center ${isActive ? 'bg-base-gradient text-white' : 'text-star-light'}`}>
                                        {item.icon}
                                    </div>
                                    <p className={`text-bodyOne md:text-subHeadingTwo ${isActive ? 'text-star-default font-bold' : 'text-star-light'}`}>{item.title}</p>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HomeJourney;
