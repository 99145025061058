import axios from 'axios';
import { BASE_URL } from './config';

const api = axios.create({
    baseURL: BASE_URL,
});

// Function to get public IP address
const getPublicIP = async () => {
    try {
        const response = await axios.get('https://api.ipify.org?format=json');
        return response.data.ip;
    } catch (error) {
        console.error('Error fetching public IP address:', error);
        return null; // or return a default IP if needed
    }
};

// Request interceptor to add the IP address
api.interceptors.request.use(async (config) => {
    const ip = await getPublicIP();
    if (ip) {
        // Add the IP to the headers
        config.headers['X-Forwarded-For'] = ip; // or any custom header name you prefer
    }
    return config;
});

// Response interceptor
api.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default api;
