import { useQuery } from "@tanstack/react-query";
import { ArrowLeft, EditPencil, Sparks } from "iconoir-react";
import LoaderGif from "../../asset/gif/loader.gif";
import AvatarIcon from "../../asset/svg/AvatarIcon";
import HeartAndThumbIcon from "../../asset/svg/HeartAndThumbIcon";
import LightBulbIcon from "../../asset/svg/LightBulbIcon";
import MiniPostCard from "../../component/MiniPostCard";
import CheckBox from "../../component/CheckBox";
import { fetchLinkedinComments } from "../../service/comment.service";
import { useNavigate } from "react-router-dom";

const CommentScreen = (props) => {
    const navigate = useNavigate();
    const {
        postId: _id,
        onClose,
        selectedComment,
        handleCheckClicked,
        handleNext,
        handleGenerateNewPost,
        handleWriteMannual,
        source
    } = props;


    const query = {
        postId: _id,
        source
    };
    const { data, isLoading } = useQuery({
        queryKey: ['linkedin-post-comment', query],
        queryFn: ({ queryKey }) => fetchLinkedinComments(queryKey[1]),
    });


    return (
        <div className="md:bg-star-light2 md:p-5">
            <div className="grid grid-cols-3 gap-5 h-[95vh]">

                {/* Left Side */}
                <div className="hidden md:block rounded-[20px] p-4 overflow-auto h-[95vh] scrollbar" style={{
                    background: 'rgba(245, 242, 255, 0.40)'
                }}>
                    <div className="flex items-center gap-1">
                        <LightBulbIcon />
                        <p className="text-bodyOneBold text-star-dark">Your Inspiration</p>
                    </div>
                    <p className="text-bodyTwo text-space-default my-4">
                        Reference post
                    </p>
                    <div>
                        <MiniPostCard {...props} />
                    </div>
                </div>

                {/* Right Side */}
                <div className="col-span-3 md:col-span-2 flex flex-col gap-5 rounded-[20px] bg-gray-moon py-5 px-2 md:p-[30px] h-[95vh]">
                    <div className="flex items-center justify-center gap-2">
                        <div className="rounded-2xl bg-star-default w-[52px] h-[5px]" />
                        <div className="rounded-2xl bg-gray-3 w-[52px] h-[5px]" />
                        <div className="rounded-2xl bg-gray-3 w-[52px] h-[5px]" />
                    </div>
                    <div>
                        <p className="text-center md:text-left text-subHeadingTwoBold bg-clip-text text-transparent bg-hover-gradient-1">
                            Let’s find some inspiration from the post comments
                        </p>
                        <p className="text-center md:text-left text-bodyTwo md:text-bodyOne">
                            Which of these top comments would you like to include in your content? Select as many as you like
                        </p>
                    </div>
                    <div className="flex-1 md:overflow-auto scrollbar flex flex-col gap-4">
                        {
                            isLoading && (
                                <div className="flex flex-col h-full justify-center items-center">
                                    <img src={LoaderGif} alt="loading" />
                                    <p className="text-bodyThreeBold text-star-light">Hang tight, inspiration is on the way!</p>
                                </div>
                            )
                        }
                        {
                            data?.data?.map((comment) => {
                                const isActive = [...selectedComment].some(selected => selected._id === comment._id);
                                return (
                                    <div key={comment._id} className={`flex gap-3 items-start p-2 rounded-lg hover:bg-star-light3 ${isActive ? 'bg-star-light3' : ''}`}>
                                        <div className="hidden md:block">
                                            <AvatarIcon width="36" height="36" src={comment?.commenterProfileImage} />
                                        </div>
                                        <div className="flex-1">
                                            <div className="bg-white px-2 py-1 border border-star-light3 " style={{
                                                borderRadius: '0px 8px 8px 8px'
                                            }}>
                                                <p className="text-subTextBold text-gray-5 md:text-bodyTwoBold md:text-space-dark">{comment?.commenterName}</p>
                                                <p className="whitespace-pre-wrap text-bodyTwo mt-2">
                                                    {comment?.commentText}
                                                </p>
                                            </div>
                                            <div className="flex items-center gap-2 mt-3">
                                                <div>
                                                    <HeartAndThumbIcon />
                                                </div>
                                                <p className="text-bodyThree">{comment?.totalLikeCount}</p>
                                            </div>
                                        </div>
                                        <div>
                                            <CheckBox checked={isActive} onChange={() => {
                                                handleCheckClicked(comment);
                                            }} />
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    {/* START: Desktop ---------------- */}
                    <div className="hidden md:flex justify-between items-center mt-auto">
                        <button className="text-btn small" onClick={() => {
                            navigate(-1)
                        }}> Cancel</button>
                        <div className="flex gap-2">
                            <button className="text-btn small" onClick={() => {
                                handleWriteMannual();
                                handleNext();
                            }}>
                                <EditPencil />
                                Write Manually
                            </button>
                            <button className="ai-btn small" onClick={() => {
                                handleGenerateNewPost();
                                handleNext();
                            }}>
                                <Sparks />
                                Generate With AI
                            </button>
                        </div>
                    </div>
                    <div className="hidden  md:flex justify-end gap-1 items-center -mt-2">
                        <p className="text-subText text-gray-5">
                            {/* <b>{subscriptionLimit.aiCreditUsage}</b> of {subscriptionLimit.aiCreditLimit} credits left */}
                        </p>
                        {/* <p className="text-bodyThree text-gray-5">•</p> */}
                        <p className="text-subText text-gray-4">powered by ChatGPT</p>
                    </div>
                    {/* END: Desktop -------------------- */}
                    {/* START: Mobile -------------------- */}
                    <div className="md:hidden min-h-[80px]"></div>
                    <div className="md:hidden p-2 bg-star-light2 fixed bottom-0 left-0 w-full flex justify-between">
                        <button className="text-btn small" onClick={() => {
                            navigate(-1)
                        }}>
                            <ArrowLeft />
                            Back
                        </button>
                        <button className="filled-btn small" onClick={() => {
                            handleGenerateNewPost();
                            handleNext();
                        }}>
                            <Sparks />
                            Generate With AI
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CommentScreen;