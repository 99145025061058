import { Xmark } from "iconoir-react";
import { Link } from "react-router-dom";
import FacebookIconDark from "../asset/svg/socialLinkDark/FacebookIconDark";
import InstaIconDark from "../asset/svg/socialLinkDark/InstaIconDark";
import LinkedIconDark from "../asset/svg/socialLinkDark/LinkedinIconDark";
import WhatsappIconDark from "../asset/svg/socialLinkDark/WhatsappIconDark";
import XIconDark from "../asset/svg/socialLinkDark/XIconDark";
import YoutubeIconDark from "../asset/svg/socialLinkDark/YoutubeIconDark";
import { links, socialLinks } from "../constent/links";
import { useEffect } from "react";
const MobileSidebar = ({ onClose }) => {
    const routes = [
        {
            name: 'Log in',
            path: links.login,
            external: true,
        },
        {
            name: 'Sign up',
            path: links.sigup,
            external: true,
        },
        {
            name: 'Pricing',
            path: links.pricing,
            external: false,
        },
        // {
        //     name:'Try for free',
        //     path:'/product-demo'
        // },
    ]

    const socialLinkList = [
        {
            name: 'Facebook',
            icon: <FacebookIconDark />,
            path: socialLinks.facebook
        },
        {
            name: 'Insta',
            icon: <InstaIconDark />,
            path: socialLinks.insta
        },
        {
            name: 'Linkedin',
            icon: <LinkedIconDark />,
            path: socialLinks.linkedin
        },
        // {
        //     name: 'X',
        //     icon: <XIconDark />,
        //     path: socialLinks.
        // },
        {
            name: 'Youtube',
            icon: <YoutubeIconDark />,
            path: socialLinks.youtube
        },
        {
            name: 'Whatsapp',
            icon: <WhatsappIconDark />,
            path: socialLinks.whatsapp
        },
    ]

    useEffect(() => {
        document.body.style.overflow = "hidden";
        return () => {
            document.body.style.overflow = "auto";
        };
    }, []);
    return (
        <div className="fixed z-[100] top-0 left-0 w-full h-full">
            <div className="blur-sm absolute border border-red-500 w-full h-full" style={{ background: 'linear-gradient(180deg, rgba(106, 101, 167, 0.00) 0%, rgba(106, 101, 167, 0.63) 72.08%)' }} />
            <div className="flex flex-col bg-white ml-auto relative z-10 h-full w-[80%]">
                <div className="flex justify-end p-2 ">
                    <div className=" p-2" onClick={onClose}>
                        <Xmark color="#5E5A73" />
                    </div>
                </div>
                <div className="flex flex-col items-center">
                    {
                        routes.map((item) => (
                            <Link key={item.name} to={item.path} className="text-bodyOne p-3 text-space-default" onClick={onClose}>{item.name}</Link>
                        ))
                    }
                </div>
                <div className="flex mt-auto items-center flex-col">
                    <a href={links.sigup} target="_blank" className="filled-btn ">Get started</a>
                    <div className="my-4">
                        <div className="flex  gap-2.5">
                            {socialLinkList.map((item) => (
                                <a key={item.name} href={item.path} target="_blank" rel="noreferrer" className="">
                                    {item.icon}
                                </a>
                            ))}
                        </div>
                        <p className="text-center text-bodyThree text-gray-6">hello@superpen.io</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default MobileSidebar;